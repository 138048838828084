import initialState from '../initial-state'
import { userActionTypes } from '../../constants/actions'

const userReducer = (state = initialState.user, action) => {
  switch (action.type) {
    case userActionTypes.LOGGEDIN_USER: {
      return { ...state, ...action.user }
    }

    case userActionTypes.LOGGEDOUT_USER: {
      return { ...state, ...action.user }
    }

    case userActionTypes.ATTACH_USER: {
      return { ...state, ...action.user }
    }
    case userActionTypes.REFRESHING_TOKEN: {
      return { ...state, refreshingPromise: action.refreshingPromise }
    }
    case userActionTypes.DETATCH_USER: {
      return { ...initialState.user }
    }
    case userActionTypes.LOGIN_USER_START: {
      const isStartLogin = true
      return { ...initialState.user, isStartLogin }
    }
    case userActionTypes.LOGIN_USER_ERROR: {
      const loginError = action.error.message
      return { ...initialState.user, loginError }
    }
    case userActionTypes.LOGIN_USER_END: {
      const isStartLogin = false
      return { ...state, isStartLogin }
    }
    default:
      return state
  }
}

export default userReducer
