import entities from '../entities'
import api from '../api'
import * as employeeConstants from '../../constants/actions/employee-actions'
import { startLoading, endLoading } from '../loading'

const loadSucess = (employees) => {
  return { type: employeeConstants.LOAD, employees: employees.data }
}

const getSucess = (employee) => {
  return { type: employeeConstants.LOAD_EMPLOYEE, employee: employee.data }
}

const autocompleteSucess = (employees) => {
  return { type: employeeConstants.AUTOCOMPLETE_EMPLOYEE, employees: employees.data }
}

const removeEmployeeSucess = (employee) => {
  return { type: employeeConstants.REMOVE_EMPLOYEE, employee: employee.data }
}

const load = (params) => {
  return (dispatch) => {
    dispatch(startLoading())

    return api.get(entities.employees, params)
      .then(employees => {
        dispatch(loadSucess(employees))
      })
      .catch(error => {
        throw (error)
      })
      .then(() => {
        dispatch(endLoading())
      })
  }
}

const autocomplete = (params) => {
  return (dispatch) => {
    dispatch(startLoading())

    return api.get(entities.employees + 'autocomplete', params)
      .then(employees => {
        dispatch(autocompleteSucess(employees))
      })
      .catch(error => {
        throw (error)
      })
      .then(() => {
        dispatch(endLoading())
      })
  }
}

const get = (id) => {
  return (dispatch) => {
    dispatch(startLoading())

    return api.get(entities.employees + id)
      .then(line => {
        dispatch(getSucess(line))
      })
      .catch(error => {
        throw (error)
      })
      .then(() => {
        dispatch(endLoading())
      })
  }
}

const remove = (id) => {
  return (dispatch) => {
    dispatch(startLoading())

    return api.remove(entities.employees + id)
      .then(response => {
        dispatch(removeEmployeeSucess(response))
      })
      .catch(error => {
        throw (error)
      })
      .then(() => {
        dispatch(endLoading())
      })
  }
}

export { load, autocomplete, get, remove }
