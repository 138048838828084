import initialState from '../initial-state'
import { loadingActionTypes } from '../../constants/actions'

const loadingReducer = (state = initialState.loading, action) => {
  switch (action.type) {
    case loadingActionTypes.START_LOADER: {
      const current = state.start

      return { ...current }
    }

    case loadingActionTypes.END_LOADER: {
      const current = state.end

      return { ...current }
    }
    default:
      return state
  }
}

export default loadingReducer
