import React from 'react'
import PropTypes from 'prop-types'
import logo from '../../images/logo.png'
import MaterialMenu from '../material-menu/material-menu'
import AppBar from '@material-ui/core/AppBar'
import { logoutUser } from '../../actions/user-actions/user-actions'
import Sidenav from '../../components/sidenav/sidenav'

class Header extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {}
  }

  render () {
    return (
      <AppBar className='h3 mb-5 bg-near-white' position='fixed'>
        <div className='flex items-center jusyify-between' id='header'>
          <div className='h-100 w-100 flex justifty-between'>

            <div className='fl w4 h-90 pa3'>
              <img className='w3' src={logo} alt='swvl logo' />
            </div>
            {
              this.props.isAuthenticated &&
              <div className='w-100 h-100'>
                <Sidenav />
              </div>
            }
          </div>
          {
            this.props.isAuthenticated &&
            (
              <div className='flex left-0 items-center near-black fw5'>
                {this.props.user.user.name.split(' ')[0]}
                <MaterialMenu
                  options={['Logout']}
                  actions={[{
                    method: logoutUser,
                    params: { token: this.props.user.token }
                  }]}
                  store={this.props.store}
                />
              </div>
            )
          }
        </div>
      </AppBar>
    )
  }
}

Header.propTypes = {
  isAuthenticated: PropTypes.bool,
  user: PropTypes.object,
  store: PropTypes.object
}

export default Header
