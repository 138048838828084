import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { urlId } from '../../utils'
import tripFields from '../../constants/fields/trip-fields'
import ListTable from '../../components/list-table/list-table'
import Page from '../page/page'
import * as tripActions from '../../actions/trip-actions/trip-actions'

const tripUrl = urlId('trips')

class TripsContainer extends Component {
  constructor (props, context) {
    super(props, context)

    this.state = { page: this.props.trips.list.page, limit: this.props.trips.list.limit }
    this.params = {}
    this.onPageChange = this.onPageChange.bind(this)
    this.onNavigateDate = this.onNavigateDate.bind(this)
    this.onRowPerPageChange = this.onRowPerPageChange.bind(this)
  }

  componentWillMount () {
    this.fetchTrips()
  }

  fetchTrips () {
    this.props.actions.load()
  }

  onPageChange (e, page) {
    if (!e) return
    this.params = Object.assign({}, this.params, { page: page + 1 })
    this.props.actions.load(this.params)
    this.setState({ page })
  }

  onRowPerPageChange (e) {
    if (!e) return
    const limit = e.target.value

    this.params = Object.assign({}, this.params, { limit })
    this.props.actions.load(this.params)
    this.setState({ limit })
  }

  onNavigateDate (date) {
    this.params = Object.assign({}, this.params, { date })
    this.props.actions.load(this.params)
  }

  render () {
    return (
      <Page
        withBackground
        tileTitle='Trips'
        tileDescription='Track your service'
        title='Business Dashboard | Trips'
      >
        <div className='ma4'>
          <ListTable
            title='Trips'
            colHeight={70}
            hasDateNavigator
            onNavigateDate={this.onNavigateDate}
            linkCreator={tripUrl}
            fields={tripFields}
            total={this.props.trips.list.total}
            page={this.state.page}
            limit={this.state.limit}
            items={this.props.trips.list.hits}
            type={this.props.trips.list.type}
            onPageChange={this.onPageChange}
            onRowPerPageChange={this.onRowPerPageChange}
          />
        </div>
      </Page>
    )
  }
}

TripsContainer.propTypes = {
  trips: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
}

const mapStateToProps = (state, ownProps) => {
  return Object.assign({}, state, { match: ownProps.match })
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(tripActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TripsContainer)
