import entities from '../entities'
import api from '../api'
import * as statsConstants from '../../constants/actions/stats-actions'
import { startLoading, endLoading } from '../loading'

const loadGenralSucess = (stats) => {
  return { type: statsConstants.LOAD_GENERAL_STATS, stats: stats.data }
}

const loadDailySucess = (stats) => {
  return { type: statsConstants.LOAD_DAILY_STATS, stats: stats.data }
}

const general = () => {
  return (dispatch) => {
    dispatch(startLoading())

    return api.get(entities.generalStats)
      .then(stats => {
        dispatch(loadGenralSucess(stats))
      })
      .catch(error => {
        throw (error)
      })
      .finally(() => {
        dispatch(endLoading())
      })
  }
}

const daily = (params) => {
  return (dispatch) => {
    dispatch(startLoading())

    return api.get(entities.dailyStats, params)
      .then(stats => {
        dispatch(loadDailySucess(stats))
      })
      .catch(error => {
        throw (error)
      })
      .finally(() => {
        dispatch(endLoading())
      })
  }
}

export { general, daily }
