import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Helmet } from 'react-helmet'
import * as userActions from '../../actions/user-actions/user-actions'
import LoginBox from './login-box/login-box'

class LoginContainer extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = { email: '', password: '' }
    this.onSubmit = this.onSubmit.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onAuthenticate = this.onAuthenticate.bind(this)
  }

  componentWillReceiveProps ({ user }) {
    if (user.token) {
      this.setState({ user })
      this.onAuthenticate(user)
    }

    if (user.loginError) {
      this.setState({ error: user.loginError })
    }

    if (typeof user.isStartLogin !== 'undefined') {
      this.setState({ isLoading: user.isStartLogin })
    }
  }

  isFormValid () {
    return true
  }

  onAuthenticate (user) {
    this.props.actions.authenticateUser(user)
    this.props.history.push('/')
  }

  onSubmit (event) {
    event.preventDefault()

    if (!this.isFormValid()) return

    const params = {
      email: this.state.email,
      password: this.state.password
    }
    this.props.actions.loginUser(params)
  }

  onChange (event) {
    const key = event.target.name
    const value = event.target.value

    this.setState({ [key]: value })
  }

  render () {
    return (
      <Fragment>
        <Helmet>
          <title>Business Dashboard | Login</title>
        </Helmet>
        <LoginBox
          email={this.state.email}
          password={this.state.password}
          onSubmit={this.onSubmit}
          onChange={this.onChange}
          error={this.state.error}
          isLoading={this.state.isLoading}
        />
      </Fragment>
    )
  }
}

LoginContainer.propTypes = {
  user: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(userActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer)
