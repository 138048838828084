import * as userActions from '../actions/user-actions/user-actions'

const authMiddleware = ({ dispatch, getState }) => {
  return (next) => (action) => {
    if (typeof action === 'function') {
      let state = getState()
      if (state.user && state.user.token && userActions.isExpired(state.user.expiry_date)) {
        if (!state.user.refreshingPromise || !typeof state.user.refreshingPromise.then === 'function') {
          const refreshAction = userActions.refreshToken({ refresh_token: state.user.refresh_token })
          return refreshAction(dispatch).then(() => next(action))
        } else {
          return state.user.refreshingPromise.then(() => next(action))
        }
      }
    }
    return next(action)
  }
}

export default authMiddleware
