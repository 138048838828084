import React from 'react'
import PropTypes from 'prop-types'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'

const SelectInput = ({ name, value, title, items, onChange, options }) => {
  return (
    <FormControl
      {...options}
      disabled={!(items && items.length)}
      id='form-control'
    >
      <InputLabel htmlFor={name}>{title}</InputLabel>
      <Select
        value={value}
        name={name}
        onChange={onChange}
        id={name}
        input={<Input id={name} />}
      >
        {
          items && items.map(item => (
            <MenuItem key={item._id} value={item._id} id={item._id}>
              {item.name || (item._source ? item._source.name : '')}
            </MenuItem>
          ))
        }
      </Select>
    </FormControl>
  )
}

SelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  items: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  options: PropTypes.object
}

export default SelectInput
