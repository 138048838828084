import initialState from '../initial-state'
import { lineActionTypes } from '../../constants/actions'

import { map } from 'ramda'
import moment from 'moment'

const mapSchedule = item => ({
  ...item,
  name: moment(item.time).format('hh:MM a')
})

const mapLine = item => ({
  _source: {
    ...item._source,
    schedules: map(mapSchedule)(item._source.schedules)
  },
  _id: item._id
})

const mapLines = map(mapLine)

const lineReducer = (state = initialState.line, action) => {
  switch (action.type) {
    case lineActionTypes.LOAD: {
      const hits = mapLines(action.lines.hits)
      const page = action.param && action.param.page ? action.param.page - 1 : state.list.page
      const limit = action.param && action.param.limit ? action.param.limit : state.list.limit
      const list = { ...state.list, ...action.lines, hits, page, limit }

      return { ...state, list }
    }

    case lineActionTypes.LOAD_LINE: {
      const line = mapLine({ _id: action.line._id, _source: action.line })
      const single = { ...line }

      return { ...state, single }
    }

    case lineActionTypes.PAGINATE: {
      const page = action.page

      const list = { ...state.list, ...action.lines }
      const { hits } = list
      return {
        ...state,
        list: {
          ...list,
          hits: mapLines(hits),
          page: page + 1
        }
      }
    }

    default:
      return state
  }
}

export default lineReducer
