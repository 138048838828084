import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

const FormDialog = ({ title, error, confirmText, cancelText, handleClose, handleConfirm, onClose, children, message }) => {
  return (
    <Dialog
      open
      className='form-dialog'
      onClose={onClose}
      maxWidth='xs'
      disableBackdropClick
      fullWidth
    >
      <DialogTitle className='form-dialog-title'>
        {title}
        {error && <div className='form-dialog-title-error'>{error}</div>}
      </DialogTitle>

      <DialogContent className='form-dialog-content'>
        {
          message &&
          <div className='form-dialog-content-message'>
            {message}
          </div>
        }
        {children}
      </DialogContent>

      <DialogActions className='form-dialog-actions'>
        <Button onClick={handleClose} color='primary'>
          {cancelText || 'Cancel'}
        </Button>
        <Button onClick={handleConfirm} color='primary'>
          {confirmText || 'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

FormDialog.propTypes = {
  title: PropTypes.string.isRequired,
  error: PropTypes.string,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  message: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
}

export default FormDialog
