import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'

import corporate from './corporate-reducer/corporate-reducer'
import user from './user-reducer/user-reducer'
import lines from './line-reducer/line-reducer'
import subscriptions from './subscription-reducer/subscription-reducer'
import trips from './trip-reducer/trip-reducer'
import employees from './employee-reducer/employee-reducer'
import bookings from './booking-reducer/booking-reducer'
import stats from './stats-reducer/stats-reducer'
import loading from './loading-reducer/loading-reducer'

const rootReducer = combineReducers({
  router: routerReducer,
  corporate,
  user,
  lines,
  subscriptions,
  trips,
  employees,
  bookings,
  stats,
  loading
})

export default rootReducer
