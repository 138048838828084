import axios from 'axios'
import getAuthorizationHeaders from './headers'

const get = (endpoint, params) => {
  const auth = getAuthorizationHeaders()
  const headers = {
    Authorization: auth
  }

  return axios.get(endpoint, { params, headers })
}

export default get
