import React from 'react'
import PropTypes from 'prop-types'
import JssProvider from 'react-jss/lib/JssProvider'
import { Switch, Route } from 'react-router-dom'
import { Provider } from 'react-redux'
import { create } from 'jss'
import { createGenerateClassName, jssPreset, MuiThemeProvider } from '@material-ui/core/styles'
import configStoreDev from '../store/config-store'
import configStoreProd from '../store/config-store-prod'

import theme from './theme'

import Header from '../components/header/header'

import IsLoggedIn from './is-logged-in/is-logged-in'
import Login from './login/login'
import Home from './home/home'

import Lines from './lines/lines'
import LineDetails from './lines/line-details/line-details'

import Trips from './trips/trips'
import TripDetails from './trips/trip-details/trip-details'

import Employees from './employees/employees'
import EmployeeDetails from './employees/employee-details/employee-details'
import { PersistGate } from 'redux-persist/integration/react'

const generateClassName = createGenerateClassName()
const jss = create(jssPreset())
const { persistor } = window._env_.REACT_APP_ENV === 'production' ? configStoreProd() : configStoreDev()

jss.options.insertionPoint = document.getElementById('jss-insertion-point')

const App = ({ store }) => {
  const state = store.getState()
  const isAuthenticated = Boolean(state.user.token)
  return (
    <JssProvider jss={jss} generateClassName={generateClassName}>
      <MuiThemeProvider theme={theme}>
        <div className='app'>
          <Header isAuthenticated={isAuthenticated} user={state.user} store={store} />
          <div className='w-100 pt5'>
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <Switch>
                  <Route name='login' path='/login' component={Login} />
                  <IsLoggedIn>
                    <Route exact path='/' component={Home} />
                    <Route name='line' path='/lines/:id' component={LineDetails} />
                    <Route exact name='lines' path='/lines' component={Lines} />
                    <Route name='employee' path='/employees/:id' component={EmployeeDetails} />
                    <Route exact name='employees' path='/employees' component={Employees} />
                    <Route name='trip' path='/trips/:id' component={TripDetails} />
                    <Route exact name='trips' path='/trips' component={Trips} />
                  </IsLoggedIn>
                </Switch>
              </PersistGate>
            </Provider>
          </div>
        </div>
      </MuiThemeProvider>
    </JssProvider>
  )
}

App.propTypes = {
  store: PropTypes.object.isRequired,
  persistor: PropTypes.object
}

export default App
