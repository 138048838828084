import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'

import * as userActions from '../../actions/user-actions/user-actions'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

class IsLoggedInContainer extends React.Component {
  componentDidMount () {
    if (!this.props.isLoggedIn) {
      // TODO: set redirect url
    }
  }

  render () {
    return this.props.isLoggedIn ? this.props.children : <Redirect to='/login' />
  }
}

IsLoggedInContainer.propTypes = {
  children: PropTypes.array.isRequired,
  isLoggedIn: PropTypes.bool.isRequired
}

const mapStateToProps = (state, ownProps) => {
  return {
    isLoggedIn: Boolean(state.user.token),
    currentURL: ownProps.location.pathname
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(userActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(IsLoggedInContainer)
