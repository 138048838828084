import { map } from 'ramda'
import moment from 'moment'
import initialState from '../initial-state'
import { tripActionTypes } from '../../constants/actions'
import { phoneFormatter } from '../../utils/'

const mapTrip = item => {
  if (item && item._source && item._source.captain && item._source.captain.phone) {
    return ({
      _source: {
        ...item._source,
        bus: {
          ...item._source.bus,
          name: item._source.bus ? item._source.bus.plates : ''
        },
        date: moment(item._source.date).format('YYYY-MM-DD hh:MM a'),
        captain: {
          ...item._source.captain,
          phone: phoneFormatter(item._source.captain.phone),
          rating: Number(item._source.captain.rating).toFixed(2).toString()
        }
      },
      _id: item._id
    })
  }
}
const mapTrips = map(mapTrip)

const tripReducer = (state = initialState.trip, action) => {
  switch (action.type) {
    case tripActionTypes.LOAD: {
      const list = { ...initialState.trip.list, ...action.trips }
      const { hits } = list
      const page = action.param && action.param.page ? action.param.page - 1 : state.list.page
      const limit = action.param && action.param.limit ? action.param.limit : state.list.limit
      return {
        ...state,
        list: {
          ...list,
          page,
          limit,
          hits: mapTrips(hits)
        }
      }
    }

    case tripActionTypes.LOAD_TRIP: {
      const trip = mapTrip({ _id: action.trip._id, _source: action.trip })
      const single = { ...trip }
      return { ...state, single }
    }

    case tripActionTypes.PAGINATE: {
      const page = action.page

      const list = { ...state.list, ...action.trips }

      const { hits } = list

      return {
        ...state,
        list: {
          ...list,
          hits: mapTrips(hits),
          page: page + 1
        }
      }
    }

    case tripActionTypes.LOCATION_UPDATE: {
      const location = action.tracking.location

      return { ...state, location }
    }

    case tripActionTypes.DETACH_LOCATION_UPDATE: {
      return { ...initialState.trip }
    }

    default:
      return state
  }
}

export default tripReducer
