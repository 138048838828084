import React from 'react'
import PropTypes from 'prop-types'
import TableTab from './table-tab/table-tab'

const TabListTable = ({ tabs, table }) => {
  return (
    <div className='w-100'>
      <div className='bg-white flex mb2 overflow-container-ns overflow-container'>
        {tabs}
      </div>
      <div>
        {table}
      </div>
    </div>
  )
}

TabListTable.propTypes = {
  tabs: PropTypes.array.isRequired,
  table: PropTypes.object.isRequired
}

export { TabListTable as default, TableTab }
