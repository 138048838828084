/* global google */
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import bus from '../../images/bus.png'
import lightStyles from '../../constants/maps/light-styles'
import markerIcon from '../../images/marker.png'
import { GoogleMap, LoadScript, DirectionsService, Polyline, Marker, OverlayView, InfoWindow } from '@react-google-maps/api'
import { throwError } from 'rxjs'

const MAP_URL = window._env_.REACT_APP_GOOGLE_MAPS_KEY

export class MapCard extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      isOpen: false,
      mapLoaded: false,
      directionsResponse: null,
      directionsPath: null,
      mapWidth: '500px',
      mapHeight: '245px'
    }

    this.onToggleOpen = this.onToggleOpen.bind(this)
    this.directionsCallback = this.directionsCallback.bind(this)
    this.onLoadMapCallback = this.onLoadMapCallback.bind(this)
    this.unmountMarkerCallback = this.unmountMarkerCallback.bind(this)
    this.unmountPolylineCallback = this.unmountPolylineCallback.bind(this)
    this.loadPolylineCallback = this.loadPolylineCallback.bind(this)
  }

  componentDidMount () {
    const mapCard = document.getElementById('map-card')
    if (mapCard) {
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({
        mapWidth: `${mapCard}.offsetWidth}px`,
        mapHeight: `${mapCard}.offsetHeight}px`
      })
    }
  }

  componentWillReceiveProps ({ directions }) {
    if (directions) this.calculateDistance(directions.request)
  }

  shouldComponentUpdate (nextProps) {
    return (
      nextProps.directions === this.props.directions ||
      nextProps.waypoints === this.props.waypoints ||
      nextProps.markers === this.props.markers ||
      nextProps.origin === this.props.origin ||
      nextProps.destination === this.props.destination ||
      nextProps.defaultCenter === this.props.defaultCenter ||
      nextProps.trackingLocation === this.props.trackingLocation
    )
  }

  calculateDistance (data) {
    const distanceService = new google.maps.DistanceMatrixService()

    return new Promise((resolve, reject) => {
      distanceService.getDistanceMatrix({
        origins: [data.origin],
        destinations: [data.destination],
        travelMode: data.travelMode
      }, (response, status) => {
        if (status === 'OK') resolve(response)
        else reject(status)
      })
    })
  }

  onToggleOpen (index) {
    return () => this.setState({ isOpen: !this.state.isOpen, index })
  }

  directionsCallback (response) {
    if (response !== null) {
      if (response.status === 'OK') {
        this.setState(
          () => ({
            directionsResponse: response,
            directionsPath: response.routes[0].overview_path
          })
        )
      } else {
        // eslint-disable-next-line no-console
        throwError(console.warn('Request Renderer Error'))
      }
    }
  }

  onLoadMapCallback (map) {
    const mapLightStyle = new google.maps.StyledMapType(lightStyles)
    this.stationMarkerStyles = {
      url: markerIcon,
      scaledSize: new google.maps.Size(30, 30),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(14, 14)
    }

    this.busMarkerStyles = {
      url: bus,
      scaledSize: new google.maps.Size(25, 45)
    }

    map.mapTypes.set('light-style', mapLightStyle)
    map.setMapTypeId('light-style')
    this.setState({
      mapLoaded: true
    })

    let bounds = new google.maps.LatLngBounds()
    bounds.extend(this.props.markers[this.props.markers.length - 1].position)
    bounds.extend(this.props.markers[0].position)
    map.fitBounds(bounds)

    this.map = map
  }

  unmountMarkerCallback (marker) {
    marker.setMap(null)
  }

  unmountPolylineCallback (polyline) {
    this.setState({
      directionsPath: null
    })

    polyline.setMap(null)
  }

  loadPolylineCallback (polyline) {
    polyline.setMap(this.map)
  }

  drawCardMarkers () {
    return this.props.markers.map((marker, index) => (
      <Marker
        key={marker.title}
        position={marker.position}
        onClick={this.onToggleOpen(index)}
        icon={this.stationMarkerStyles}
        onUnmount={this.unmountMarkerCallback}
      >
        {
          this.state.isOpen && index === this.state.index &&
          <InfoWindow
            onCloseClick={this.onToggleOpen}
            position={marker.position}
          >
            <div>
              <div>{marker.title}</div>
              <div>{marker.description}</div>
            </div>
          </InfoWindow>
        }
      </Marker>
    ))
  }

  drawCardRoute () {
    return (
      <Polyline
        path={this.state.directionsPath}
        options={{
          strokeColor: 'crimson',
          strokeOpacity: 1,
          strokeWeight: 3
        }}
        onUnmount={this.unmountPolylineCallback}
        onLoad={this.loadPolylineCallback}
      />
    )
  }

  drawCardTrackingMarker () {
    const defaultTracking = this.props.trackingLocation.location
    return (
      <OverlayView
        position={{
          lat: (defaultTracking) ? defaultTracking.lat : this.props.trackingLocation.lat,
          lng: (defaultTracking) ? defaultTracking.lng : this.props.trackingLocation.lng
        }}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      >
        <img
          className='w-25'
          src={bus}
          alt='tracking-bus'
          style={{
            transform: `rotate(${this.props.trackingLocation.bearing}deg)`
          }} />
      </OverlayView>
    )
  }

  addDirectionsService () {
    const travelMode = 'DRIVING'
    return (
      <DirectionsService
        options={{
          destination: this.props.destination,
          origin: this.props.origin,
          waypoints: this.props.waypoints,
          travelMode: travelMode
        }}
        callback={this.directionsCallback}
      />
    )
  }

  render () {
    return (
      <LoadScript
        googleMapsApiKey={MAP_URL}
        loadingElement={
          <div
            style={{
              height: '100%',
              width: '100%'
            }}
          />
        }
      >
        <div className='h-100-l h-100-m h-50-ns w-100' id='map-container'>
          <GoogleMap
            id='map'
            onLoad={this.onLoadMapCallback}
            MapTypeId={lightStyles}
            mapContainerStyle={{
              height: this.state.mapWidth,
              width: this.state.mapHeight
            }}
            options={{
              disableDefaultUI: true
            }}
            onUnmount={this.onUnmountMapCallback}
          >
            <Fragment>
              {
                this.props.origin &&
                this.props.waypoints &&
                this.props.destination &&
                this.addDirectionsService()
              }
              {
                this.state.directionsResponse !== null &&
                this.state.directionsPath !== null &&
                this.drawCardRoute()
              }
              {
                this.state.mapLoaded &&
                this.props.markers.length &&
                this.drawCardMarkers()
              }
              {
                this.props.trackingLocation &&
                this.props.trackingLocation.lat &&
                this.props.trackingLocation.lng &&
                this.drawCardTrackingMarker()
              }
            </Fragment>
          </GoogleMap>
        </div>
      </LoadScript>
    )
  }
}

MapCard.propTypes = {
  google: PropTypes.object,
  directions: PropTypes.object,
  options: PropTypes.object,
  isOpen: PropTypes.bool,
  onToggleOpen: PropTypes.func,
  waypoints: PropTypes.array.isRequired,
  markers: PropTypes.array.isRequired,
  origin: PropTypes.string.isRequired,
  destination: PropTypes.string.isRequired,
  defaultCenter: PropTypes.object.isRequired,
  trackingLocation: PropTypes.object,
  tripStatus: PropTypes.string,
  zoomLevel: PropTypes.number
}

export default React.memo(MapCard)
