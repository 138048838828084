import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'

const ConfirmDialog = ({ title, description, confirmText, onClose, cancelText, handleClose, handleConfirm }) => {
  return (
    <Dialog
      open
      disableBackdropClick
      onClose={onClose}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='primary'>
          {cancelText || 'Cancel'}
        </Button>
        <Button onClick={handleConfirm} color='primary' autoFocus>
          {confirmText || 'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ConfirmDialog.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  handleClose: PropTypes.func,
  handleConfirm: PropTypes.func,
  onClose: PropTypes.func
}

export default ConfirmDialog
