const list = { hits: [], total: 0, page: 0, limit: 20 }

export default {
  corporate: {},
  user: { email: '', password: '' },
  line: {
    list: {
      ...list
    }
  },
  subscription: {
    list: {
      ...list
    }
  },
  trip: {
    list: {
      ...list
    },
    single: {},
    location: {}
  },
  employee: {
    list: {
      ...list
    },
    single: {},
    suggestions: []
  },
  booking: {
    list: {
      ...list
    },
    user: {
      upcomingBookings: {
        list: {
          ...list
        }
      },
      pastBookings: {
        list: {
          ...list
        }
      }
    }
  },
  stats: {
    daily: {},
    general: {}
  },
  loading: {
    start: 0,
    end: 100,
    current: 0
  }
}
