import React from 'react'
import PropTypes from 'prop-types'

const PageTile = ({ title, description }) => {
  return (
    <div className='override flex justify-between flex-column-m items-center w-100 bg-white pa2 pa3-ns page-tile'>
      <span className='f6 ml3 mb1 ttu resp-none'>{description}</span>
      <span className='f6 mr3 ttu tracked grow no-underline ph3 pv2 mb2'>{title}</span>
    </div>
  )
}

PageTile.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
}

export default PageTile
