import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVertIcon from '@material-ui/icons/MoreVert'

const ITEM_HEIGHT = 48

class MaterialMenu extends React.Component {
  constructor (props, context) {
    super(props, context)
    this.state = { anchorEl: null }
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  onMenuItemSelected = action => event => {
    this.handleClose()
    this.props.store.dispatch(action.method(action.params))
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  render () {
    const { anchorEl } = this.state
    const open = Boolean(anchorEl)

    return (
      <Fragment>
        <IconButton
          aria-label="More"
          aria-owns={open ? 'long-menu' : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={this.handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: 200
            }
          }}
        >
          {
            this.props.options && this.props.options.length > 0 &&
            this.props.options.map((option, index) => (
              <MenuItem key={option} onClick={this.onMenuItemSelected(this.props.actions[index])}>
                {option}
              </MenuItem>
            ))}
        </Menu>
      </Fragment>
    )
  }
}

MaterialMenu.propTypes = {
  options: PropTypes.array,
  actions: PropTypes.array,
  store: PropTypes.object
}

export default MaterialMenu
