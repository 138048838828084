import { map, findIndex } from 'ramda'
import initialState from '../initial-state'
import { subscriptionActionTypes, snakActionTypes } from '../../constants/actions'

import moment from 'moment'

const mapSubscription = item => ({
  _source: {
    ...item._source,
    timing: moment(item._source.schedule.time).format('hh:MM a')
  },
  _id: item._id
})

const mapSubscriptions = map(mapSubscription)

const subscriptionReducer = (state = initialState.subscription, action) => {
  switch (action.type) {
    case subscriptionActionTypes.LOAD_SUBSCRIPTION: {
      const hits = mapSubscriptions(action.subscriptions.hits)
      const total = action.subscriptions.total
      const page = action.param && action.param.page ? action.param.page - 1 : state.list.page
      const list = { ...state.list, hits, total, page }
      return { ...state, list }
    }

    case subscriptionActionTypes.ADD_SUBSCRIPTION: {
      const newSubscription = mapSubscription({ _id: action.subscription._id, _source: action.subscription })
      const hits = [newSubscription, ...state.list.hits]
      const total = state.list.total + 1
      const list = Object.assign({}, state.list, { hits }, { total })
      const newlyAdded = true

      return { ...state, list, newlyAdded }
    }

    case subscriptionActionTypes.ADD_SUBSCRIPTION_ERROR: {
      const addError = action.error.message
      const newlyAdded = false

      return { ...state, addError, newlyAdded }
    }

    case subscriptionActionTypes.REMOVE_SUBSCRIPTION: {
      const findRemovedIndex = item => item._id === action.subscription._id
      const removedIndex = findIndex(findRemovedIndex)(state.list.hits)
      const hits = [
        ...state.list.hits.slice(0, removedIndex),
        ...state.list.hits.slice(removedIndex + 1)
      ]
      const total = state.list.total - 1
      const list = { ...state.list, hits, total }
      const newlyRemoved = true

      return { ...state, list, newlyRemoved }
    }

    case subscriptionActionTypes.UPDATE_SUBSCRIPTION: {
      const updatedSubscription = mapSubscription({ _id: action.subscription._id, _source: action.subscription })
      const findUpdatedIndex = item => item._id === action.subscription._id
      const updatedIndex = findIndex(findUpdatedIndex)(state.list.hits)
      const hits = [
        ...state.list.hits.slice(0, updatedIndex),
        updatedSubscription,
        ...state.list.hits.slice(updatedIndex + 1)
      ]

      const list = { ...state.list, hits }
      const newlyUpdated = true

      return { ...state, list, newlyUpdated }
    }

    case subscriptionActionTypes.UPDATE_SUBSCRIPTION_ERROR: {
      const updateError = action.error.message

      return { ...state, updateError }
    }

    case snakActionTypes.REMOVE_SNAK: {
      const newlyUpdated = false
      const newlyAdded = false
      const newlyRemoved = false

      return { ...state, newlyUpdated, newlyAdded, newlyRemoved }
    }

    default:
      return state
  }
}

export default subscriptionReducer
