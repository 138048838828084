import initialState from '../initial-state'
import { statsActionTypes } from '../../constants/actions'

const mapDaily = item => ({
  ...item,
  punctuality: Math.round(item.punctuality / 100000)
})

const statsReducer = (state = initialState.stats, action) => {
  switch (action.type) {
    case statsActionTypes.LOAD_DAILY_STATS: {
      const daily = mapDaily(action.stats)
      return { ...state, daily }
    }

    case statsActionTypes.LOAD_GENERAL_STATS: {
      const general = action.stats

      return { ...state, general }
    }

    default:
      return state
  }
}

export default statsReducer
