import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { urlId } from '../../utils'
import { connect } from 'react-redux'
import employeeFields from '../../constants/fields/employee-fields'
import ListTable from '../../components/list-table/list-table'
import Page from '../page/page'
import * as employeeActions from '../../actions/employee-actions/employee-actions'

const employeeUrl = urlId('employees')

class EmployeesContainer extends React.Component {
  constructor (props, context) {
    super(props, context)

    const employeeAutocomplete = ''

    this.params = {}
    this.state = {
      page: this.props.employees.list.page,
      limit: this.props.employees.list.limit,
      employeeAutocomplete
    }
    this.onPageChange = this.onPageChange.bind(this)
    this.onRowPerPageChange = this.onRowPerPageChange.bind(this)
    this.onAutoCompleteTyping = this.onAutoCompleteTyping.bind(this)
    this.onAutocompleteClick = this.onAutocompleteClick.bind(this)
  }

  componentDidMount () {
    this.fetchEmployees()
  }

  fetchEmployees () {
    this.props.actions.load()
  }

  onPageChange (e, page) {
    if (!e) return
    this.params = Object.assign({}, this.params, { page: page + 1 })
    this.props.actions.load(this.params)

    this.setState({ page })
  }

  onRowPerPageChange (e) {
    if (!e) return
    const limit = e.target.value

    this.params = Object.assign({}, this.params, { limit })
    this.props.actions.load(this.params)
    this.setState({ limit })
  }

  onAutoCompleteTyping (e) {
    const query = e.target.value

    this.setState({ employeeAutocomplete: query })
    this.props.actions.autocomplete({ query })
  }

  onAutocompleteClick (item) {
    return () => {
      this.props.history.push(`employees/${item._id}`)
    }
  }

  render () {
    return (
      <Page
        withBackground
        tileTitle='people'
        tileDescription='Manage your employees using our service'
        title='Business Dashboard | Employees'
      >
        <div className='ma4'>
          <ListTable
            title='Employees'
            className=''
            colHeight={70}
            hasAutocomplete
            autocompleteSearchText={this.state.employeeAutocomplete}
            autocompletePlaceholder='Search for employees'
            autocompleteSuggestions={this.props.employees.suggestions}
            autocompleteDescriptionKey={['email', 'phone']}
            linkCreator={employeeUrl}
            fields={employeeFields}
            total={this.props.employees.list.total}
            page={this.state.page}
            limit={this.state.limit}
            items={this.props.employees.list.hits}
            type={this.props.employees.list.type}
            onPageChange={this.onPageChange}
            onRowPerPageChange={this.onRowPerPageChange}
            onAutoCompleteTyping={this.onAutoCompleteTyping}
            onAutocompleteClick={this.onAutocompleteClick}
          />
        </div>
      </Page>
    )
  }
}

EmployeesContainer.propTypes = {
  employees: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

const mapStateToProps = (state, ownProps) => {
  const { history } = ownProps
  return Object.assign({}, state, { history })
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(employeeActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmployeesContainer)
