import entities from '../entities'
import api from '../api'
import * as lineConstants from '../../constants/actions/line-actions'
import { startLoading, endLoading } from '../loading'

const loadSuccess = (lines, param) => {
  return { type: lineConstants.LOAD, lines: lines.data, param }
}

const getSuccess = (line) => {
  return { type: lineConstants.LOAD_LINE, line: line.data }
}

const load = (params) => {
  return (dispatch) => {
    dispatch(startLoading())

    return api.get(entities.lines, params)
      .then(lines => {
        dispatch(loadSuccess(lines, params))
      })
      .catch(error => {
        throw (error)
      })
      .then(() => {
        dispatch(endLoading())
      })
  }
}

const get = (id) => {
  return (dispatch) => {
    dispatch(startLoading())

    return api.get(entities.lines + id)
      .then(line => {
        dispatch(getSuccess(line))
      })
      .catch(error => {
        throw (error)
      })
      .then(() => {
        dispatch(endLoading())
      })
  }
}

export { load, get }
