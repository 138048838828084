import React from 'react'
import PropTypes from 'prop-types'

const StatCard = ({ title, count, className }) => {
  const classNames = className ? `stat-card ${className}` : 'stat-card primary'

  return (
    <div className={`card-resp pa4 w-20-l w-40-m  w-100-ns center ma4 bg-white ${classNames}`}>
      <div className='mr6-l ttu'>{title}</div>
      <div className='f2 f-subheadline-l fw6 ml0 stat-card-count'>{count}</div>
    </div>
  )
}

StatCard.propTypes = {
  title: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string
}

export default StatCard
