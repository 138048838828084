import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import EmailInput from '../../../components/email-input/email-input'
import PasswordInput from '../../../components/password-input/password-input'
import SubmitButton from '../../../components/submit-button/submit-button'
import autofill from 'react-autofill'

const styles = {
  spacing: 0
}

const LoginBox = ({ email, password, onSubmit, onChange, error, isLoading }) => {
  return (
    <div
      justify='center'
      spacing={styles.spacing}
    >
      <div className='w-80-l w-80-m w-80-ns center mt6-l mt6-m' id='login-box' md={4}>
        <Fragment>
          <legend className='center mb3 mb2 loginbox-section-title'>Login to your dashboard</legend>
          <form className='measure center mt5-m mt5-ns mt5-l' id='login-form'>
            <EmailInput
              name='email'
              value={email}
              onChange={onChange}
              isRequired
            />
            <PasswordInput
              name='password'
              value={password}
              onChange={onChange}
              isRequired
            />
            {
              error &&
              <div className='tracked avenir mt3 center'>
                <span className='red ttc .b--red f6 avenir'>{error}</span>
              </div>
            }

            <SubmitButton
              text='Login'
              isLoading={isLoading}
              onSubmit={onSubmit}
              isDisabled={isLoading}
            />
          </form>
        </Fragment>
      </div>
    </div>

  )
}

LoginBox.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  email: PropTypes.string,
  password: PropTypes.string,
  isLoading: PropTypes.bool
}

export default autofill(LoginBox)
