import axios from 'axios'
import getAuthorizationHeaders from './headers'

const post = (endpoint, data) => {
  const auth = getAuthorizationHeaders()
  const headers = {
    Authorization: auth
  }

  return axios({
    method: 'post',
    url: endpoint,
    data,
    headers
  })
}

export default post
