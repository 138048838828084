import * as loadingActions from '../constants/actions/loading-actions'

const startLoading = () => {
  return { type: loadingActions.START_LOADER }
}

const endLoading = () => {
  return { type: loadingActions.END_LOADER }
}

export { startLoading, endLoading }
