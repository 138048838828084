import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import { connect } from 'react-redux'
import TripDetailsView from './trip-details-view/trip-details-view'
import Page from '../../page/page'
import tripTabs from '../../../constants/tabs/trip-tabs'
import bookingFields from '../../../constants/fields/booking-fields'
import * as tripActions from '../../../actions/trip-actions/trip-actions'
import * as bookingActions from '../../../actions/booking-actions/booking-actions'
import * as trackingActions from '../../../actions/tracking-actions/tracking-actions'

class TripDetailsContainer extends React.Component {
  constructor (props, context) {
    super(props, context)

    const ride = props.match.params.id
    this.state = {
      tableActiveTab: 0,
      tableActiveFields: bookingFields,
      tableActiveItems: {},
      params: { ride }
    }
    this.onPageChange = this.onPageChange.bind(this)
    this.onRowPerPageChange = this.onRowPerPageChange.bind(this)
  }

  componentDidMount () {
    this.get(this.state.params.ride)
    this.getBookings(this.state.params)
    this.props.tracking(this.state.params.ride)
  }

  componentWillReceiveProps ({ trips, bookings }) {
    if (trips) {
      if (typeof trips.location === 'string') trips.location = JSON.parse(trips.location)
      this.setState({ trip: trips.single, trackingLocation: trips.location })
    }
    if (bookings) this.setState({ tableActiveItems: bookings.list })
  }

  get (id) {
    this.props.get(id)
  }

  getBookings (param) {
    this.props.getBookings(param)
  }
  onPageChange (e, page) {
    if (!e) return
    const params = { ...this.state.params, page: page + 1 }

    this.getBookings(params)
    this.setState(params)
  }
  onRowPerPageChange (e) {
    if (!e) return
    const limit = e.target.value
    const params = { ...this.state.params, limit }

    this.setState({ params })
    this.getBookings(params)
  }

  render () {
    if (!this.state.trip || isEmpty(this.state.trip) || isEmpty(this.state.tableActiveItems)) return null

    return (
      <div>
        <Page
          title='Business Dashboard | Trips'
          tileTitle='Trips'
          tileDescription='Track your service'
        >
          <TripDetailsView
            trip={this.state.trip}
            tableTabs={tripTabs}
            tableActiveTab={this.state.tableActiveTab}
            tableActiveItems={this.state.tableActiveItems}
            tableActiveFields={this.state.tableActiveFields}
            trackingLocation={this.state.trackingLocation}
            onPageChange={this.onPageChange}
            onRowPerPageChange={this.onRowPerPageChange}
          />
        </Page>
      </div>
    )
  }
}

TripDetailsContainer.propTypes = {
  get: PropTypes.func.isRequired,
  getBookings: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  trips: PropTypes.object.isRequired,
  bookings: PropTypes.object,
  tracking: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => {
  return {
    get: id => dispatch(tripActions.get(id)),
    getBookings: param => dispatch(bookingActions.load(param)),
    tracking: id => dispatch(trackingActions.locationTracking(id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TripDetailsContainer)
