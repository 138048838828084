import io from 'socket.io-client'
import * as tripAction from '../../constants/actions/trip-actions'

const locationUpdateSuccess = (tracking) => {
  return { type: tripAction.LOCATION_UPDATE, tracking }
}

const locationTracking = (trip) => {
  const auth = JSON.parse(localStorage.getItem('auth'))
  const token = auth.jwt
  const socket = io(window._env_.REACT_APP_SOCKETS_URL, { query: { token }, transports: ['websocket'] })

  return (dispatch) => {
    socket.on('connect', () => {
      socket.emit('ride:subscribe', { rideId: trip })
    })

    socket.on('ride:location:update', data => {
      dispatch(locationUpdateSuccess(data))
    })

    socket.on('connect_error', err => {
      throw (err)
    })
  }
}

export { locationTracking }
