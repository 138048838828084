import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { isEmpty } from 'ramda'
import { urlId } from '../../../utils'
import * as lineActions from '../../../actions/line-actions/line-actions'
import * as subscriptionsActions from '../../../actions/subscription-actions/subscription-actions'
import * as employeeActions from '../../../actions/employee-actions/employee-actions'
import LineDetailsView from './line-details-view/line-details-view'
import { removeSnaks } from '../../../actions/snaks-actions/snaks-actions'
import subscriptionFields from '../../../constants/fields/subscription-fields'
import employeeMessages from '../../../constants/messages/employee-message'
import Page from '../../page/page'

const subscriptionUrl = urlId('subscriptions')

class LineDetailsContainer extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.newSubscription = {
      user: {},
      pickup: '',
      dropoff: '',
      time: ''
    }
    const tableActions = [
      {
        name: 'editSubs',
        title: 'Edit',
        icon: 'edit',
        method: this.editSubscription.bind(this)
      },
      {
        name: 'cancelSubs',
        title: 'Cancel',
        icon: 'close',
        method: this.cancelSubscription.bind(this)
      }
    ]

    this.state = {
      tableActiveTab: 0,
      tableActiveFields: subscriptionFields,
      tableActiveItems: [],
      tableActiveLink: subscriptionUrl,
      isEditDialogOpen: false,
      isCancelDialogOpen: false,
      cancelItem: null,
      confirmItem: null,
      newSubscription: this.newSubscription,
      employeeAutocomplete: '',
      tableActions,
      params: {}
    }

    this.onAddClose = this.onAddClose.bind(this)
    this.onEditClose = this.onEditClose.bind(this)
    this.onAddConfirm = this.onAddConfirm.bind(this)
    this.onEditChange = this.onEditChange.bind(this)
    this.onEditConfirm = this.onEditConfirm.bind(this)
    this.onCancelClose = this.onCancelClose.bind(this)
    this.onSnacksClose = this.onSnacksClose.bind(this)
    this.onDialogClose = this.onDialogClose.bind(this)
    this.onCancelConfirm = this.onCancelConfirm.bind(this)
    this.addSubscription = this.addSubscription.bind(this)
    this.getSubscription = this.getSubscription.bind(this)
    this.editSubscription = this.editSubscription.bind(this)
    this.cancelSubscription = this.cancelSubscription.bind(this)
    this.onRowPerSubsChange = this.onRowPerSubsChange.bind(this)
    this.onAutocompleteClick = this.onAutocompleteClick.bind(this)
    this.paginateSubscription = this.paginateSubscription.bind(this)
    this.onAutoCompleteTyping = this.onAutoCompleteTyping.bind(this)
    this.onAddSubscriptionChange = this.onAddSubscriptionChange.bind(this)
  }
  componentDidMount () {
    const lineId = this.props.match.params.id
    this.get(lineId)
  }
  componentWillReceiveProps ({ lines, subscriptions, employees }) {
    const newState = {
      line: lines.single,
      tableActiveItems: subscriptions.list,
      employeeSuggestions: employees.suggestions
    }

    if (subscriptions && subscriptions.addError) {
      const newSubscription = Object.assign({}, this.state.newSubscription, { error: subscriptions.addError })

      this.setState({ newSubscription })
    }

    if (subscriptions && subscriptions.newlyAdded) {
      const snaks = employeeMessages.addSubscription
      this.setState({ isAddDialogOpen: false, newSubscription: this.newSubscription, snaks })
    }

    if (subscriptions && subscriptions.newlyRemoved) {
      const snaks = employeeMessages.removeSubscription
      this.setState({ snaks })
    }

    if (subscriptions && subscriptions.newlyUpdated) {
      const snaks = employeeMessages.updateSubscription
      this.setState({ isEditDialogOpen: false, newSubscription: this.newSubscription, snaks })
    }

    if (subscriptions && subscriptions.updateError) {
      const editItem = Object.assign({}, this.state.editItem, { error: subscriptions.updateError })

      this.setState({ editItem })
    }
    this.setState(newState)
  }
  get (id) {
    this.props.get(id)
      .then(() => {
        const schedules = this.state.line._source.schedules
        const schedule = schedules[this.state.tableActiveTab]
        const params = { schedule: schedule._id }

        this.setState({ params })
        this.getSubscription(params)
      })
  }
  getSubscription (param) {
    this.props.getSubscriptions(param)
  }
  paginateSubscription (e, page) {
    e.persist()

    const params = { ...this.state.params, page: page + 1 }
    this.getSubscription(params)
  }
  onRowPerSubsChange () {}

  editSubscription (item) {
    this.setState({ isEditDialogOpen: true, editItem: item })
  }
  cancelSubscription (item) {
    this.setState({ isCancelDialogOpen: true, cancelItem: item })
  }
  addSubscription () {
    const newSubscription = { ...this.state.newSubscription }

    this.setState({ isAddDialogOpen: true, newSubscription })
  }

  onCancelConfirm () {
    const subscriptionId = this.state.cancelItem._id

    this.props.removeSubscription(subscriptionId)
    this.setState({ isCancelDialogOpen: false, cancelItem: null })
  }
  onCancelClose () {
    this.setState({ isCancelDialogOpen: false, cancelItem: null })
  }

  onDialogClose (key) {
    return () => {
      this.setState({ [key]: false })
    }
  }

  onEditConfirm () {
    const user = this.state.editItem._source.user._id
    const pickup = this.state.editItem._source.pick_up._id
    const dropoff = this.state.editItem._source.drop_off._id
    const schedule = this.state.editItem._source.schedule._id
    const params = {
      user,
      pick_up: pickup,
      drop_off: dropoff,
      schedule
    }
    this.props.updateSubscription(this.state.editItem._id, params)
  }
  onEditClose () {
    this.setState({ isEditDialogOpen: false, editItem: null })
  }
  onEditChange (e) {
    const key = e.target.name
    const value = e.target.value
    const editItem = {
      ...this.state.editItem,
      _source: {
        ...this.state.editItem._source,
        [key]: {
          _id: value
        }
      }
    }
    this.setState({ editItem })
  }

  onAddClose () {
    this.setState({ isAddDialogOpen: false })
  }
  onAddConfirm () {
    const params = {
      user: this.state.newSubscription.user._id,
      pick_up: this.state.newSubscription.pickup,
      schedule: this.state.newSubscription.time,
      drop_off: this.state.newSubscription.dropoff
    }

    this.props.addSubscription(params)
  }

  onAutoCompleteTyping (e) {
    const query = e.target.value

    this.setState({ employeeAutocomplete: query })
    this.props.autocomplete({ query })
  }

  onAutocompleteClick (suggestion) {
    return () => {
      const newSubscription = Object.assign({}, this.state.newSubscription, { user: suggestion })
      this.setState({ newSubscription })
    }
  }

  onAddSubscriptionChange (e) {
    const key = e.target.name
    const value = e.target.value

    const newSubscription = Object.assign({}, this.state.newSubscription, { [key]: value })
    this.setState({ newSubscription })
  }

  onSnacksClose () {
    this.setState({ snaks: null })
    this.props.removeSnaks()
  }

  render () {
    if (!this.state.line || isEmpty(this.state.line) || isEmpty(this.state.line.schedules)) return null

    return (
      <Page
        title={'Business Dashboard | ' + this.state.line.name}
        tileTitle='Lines'
        snaks={this.state.snaks}
        snaksOnClose={this.onSnacksClose}
        tileDescription='Lines, Schedules and Subscriptions'
      >
        <LineDetailsView
          line={this.state.line}
          tableTabs={this.state.line._source.schedules}
          tableActiveTab={this.state.tableActiveTab}
          tableActiveItems={this.state.tableActiveItems}
          tableActiveFields={this.state.tableActiveFields}
          tableActiveLink={this.state.tableActiveLink}
          tableActions={this.state.tableActions}
          onChangeSubsPage={this.paginateSubscription}
          onRowPerSubsChange={this.onRowPerSubsChange}

          newSubscription={this.state.newSubscription}
          onEditSubscriptionChange={this.onEditChange}

          isAddDialogOpen={this.state.isAddDialogOpen}
          isEditDialogOpen={this.state.isEditDialogOpen}
          isCancelDialogOpen={this.state.isCancelDialogOpen}

          onCancelConfirm={this.onCancelConfirm}
          onCancelClose={this.onCancelClose}
          onEditConfirm={this.onEditConfirm}
          onEditClose={this.onEditClose}
          onAddClose={this.onAddClose}
          onAddConfirm={this.onAddConfirm}
          onEditChange={this.onEditChange}
          onAutoCompleteTyping={this.onAutoCompleteTyping}

          cancelItem={this.state.cancelItem}
          confirmItem={this.state.confirmItem}
          editItem={this.state.editItem}
          addSubscription={this.addSubscription}
          onDialogClose={this.onDialogClose}

          autocompletePlaceholder={'User'}
          autocompleteSuggestions={this.state.employeeSuggestions}
          autocompleteSearchText={this.state.employeeAutocomplete}
          onAutocompleteClick={this.onAutocompleteClick}
          onAddSubscriptionChange={this.onAddSubscriptionChange}
        />
      </Page>
    )
  }
}

LineDetailsContainer.propTypes = {
  get: PropTypes.func.isRequired,
  lines: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  subscriptions: PropTypes.object.isRequired,
  autocomplete: PropTypes.func.isRequired,
  getSubscriptions: PropTypes.func.isRequired,
  updateSubscription: PropTypes.func.isRequired,
  removeSnaks: PropTypes.func.isRequired,
  addSubscription: PropTypes.func.isRequired,
  removeSubscription: PropTypes.func.isRequired,
  employees: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => {
  return {
    get: id => dispatch(lineActions.get(id)),
    autocomplete: param => dispatch(employeeActions.autocomplete(param)),
    getSubscriptions: params => dispatch(subscriptionsActions.getAll(params)),
    addSubscription: param => dispatch(subscriptionsActions.add(param)),
    updateSubscription: (id, param) => dispatch(subscriptionsActions.update(id, param)),
    removeSubscription: id => dispatch(subscriptionsActions.remove(id)),
    removeSnaks: () => dispatch(removeSnaks())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LineDetailsContainer)
