import entities from '../entities'
import api from '../api'
import * as tripConstants from '../../constants/actions/trip-actions'
import { startLoading, endLoading } from '../loading'

const loadSucess = (trips, param) => {
  return { type: tripConstants.LOAD, trips: trips.data, param }
}

const getSucess = (trip) => {
  return { type: tripConstants.LOAD_TRIP, trip: trip.data }
}

const load = (params) => {
  return (dispatch) => {
    dispatch(detatchTrip())
    dispatch(startLoading())

    return api.get(entities.trips, params)
      .then(trips => {
        dispatch(loadSucess(trips, params))
      })
      .catch(error => {
        throw (error)
      })
      .then(() => {
        dispatch(endLoading())
      })
  }
}

const detatchTrip = () => {
  return { type: tripConstants.DETACH_LOCATION_UPDATE }
}

const get = (id) => {
  return (dispatch) => {
    dispatch(startLoading())

    return api.get(entities.trips + id)
      .then(trip => {
        dispatch(getSucess(trip))
      })
      .catch(error => {
        throw (error)
      })
      .then(() => {
        dispatch(endLoading())
      })
  }
}

export { load, get }
