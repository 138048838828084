import axios from 'axios'
import getAuthorizationHeaders from './headers'

const remove = (endpoint) => {
  const auth = getAuthorizationHeaders()
  const headers = {
    Authorization: auth
  }

  return axios.delete(endpoint, { headers })
}

export default remove
