import React from 'react'
import PropTypes from 'prop-types'
import InfoCardItem from './info-card-item/info-card-item'

const InfoCard = ({ children, bordered }) => {
  const classNames = `bg-white pa4-l pa2-m pa3 h4-l flex flex-row-l flex-column-m override-flex-column-ns ${bordered ? 'bb b--light-gray' : ''}`
  return (
    <div className={classNames}>
      {children}
    </div>
  )
}

InfoCard.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  bordered: PropTypes.bool
}

export { InfoCard as default, InfoCardItem }
