import React from 'react'
import PropTypes from 'prop-types'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableFooter from '@material-ui/core/TableFooter'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'

import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import Tooltip from '@material-ui/core/Tooltip'
import Toolbar from '@material-ui/core/Toolbar'
import Paper from '@material-ui/core/Paper'
import ListTitle from './list-title/list-title'
import ListRow from './list-row/list-row'

const ListTable = ({ title, fields, page, limit, items, type, total,
  onPageChange, onRowPerPageChange, options,
  linkCreator, theme, colHeight, hasAdd, addTooltip, hasActions, actions, onAddClick, hasDateNavigator,
  onNavigateDate, hasAutocomplete, onAutoCompleteTyping, autocompletePlaceholder, autocompleteSuggestions,
  autocompleteSearchText, onAutocompleteClick, autocompleteDescriptionKey }) => {
  const isLightClassNames = theme === 'light' ? 'light' : ''
  return (
    <Paper className='w-96-l ma1-ns w-99-ns flex-column'>
      <div className='bg-white'>
        {
          title &&
          <ListTitle
            title={title}
            count={total}
            hasDateNavigator={hasDateNavigator}
            onNavigateDate={onNavigateDate}
            hasAutocomplete={hasAutocomplete}
            autocompletePlaceholder={autocompletePlaceholder}
            autocompleteSuggestions={autocompleteSuggestions}
            onAutoCompleteTyping={onAutoCompleteTyping}
            autocompleteSearchText={autocompleteSearchText}
            onAutocompleteClick={onAutocompleteClick}
            autocompleteDescriptionKey={autocompleteDescriptionKey}
          />
        }
        {
          hasAdd &&
          <Toolbar
          >
            <Tooltip id='tooltip-left' title={addTooltip} placement='left'>
              <Fab size='small' id='table-cell-add' color='primary' aria-label='Add' onClick={onAddClick}>
                <AddIcon />
              </Fab>
            </Tooltip>
          </Toolbar>
        }
      </div>
      <div className='overflow-container-ns'>
        <Table
          className={'table ' + isLightClassNames}
          border={0} {...options}
        >
          <TableHead id='table-head'>
            <TableRow className='table-row' style={{ height: `${colHeight}px` }}>
              {
                fields.map(field => (
                  <TableCell key={field.name}>{field.title}</TableCell>
                ))
              }
              {
                actions && actions.length &&
                  (<th className='left-0' />)
              }
            </TableRow>
          </TableHead>
          <TableBody id='table-body'>
            {
              items && items.length > 0 &&
              items.map(item => (
                item &&
                <ListRow
                  hasActions={Boolean(hasActions)}
                  key={item._id}
                  actions={actions}
                  linkCreator={linkCreator}
                  fields={fields}
                  item={item}
                  type={type}
                  colHeight={colHeight}
                />
              ))
            }
          </TableBody>
          <TableFooter id='table-footer'>
            <TableRow className='table-row'>
              <TablePagination
                style={{ height: `${colHeight}px` }}
                count={total}
                rowsPerPage={limit}
                page={page}
                onChangePage={onPageChange}
                onChangeRowsPerPage={onRowPerPageChange}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    </Paper>
  )
}

ListTable.propTypes = {
  fields: PropTypes.array.isRequired,
  limit: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  items: PropTypes.array.isRequired,
  type: PropTypes.string,
  onPageChange: PropTypes.func,
  onAddClick: PropTypes.func,
  onRowPerPageChange: PropTypes.func,
  title: PropTypes.string,
  actions: PropTypes.array,
  total: PropTypes.number,
  options: PropTypes.object,
  linkCreator: PropTypes.func,
  theme: PropTypes.string,
  colHeight: PropTypes.number,
  hasActions: PropTypes.bool,
  hasAdd: PropTypes.bool,
  addTooltip: PropTypes.string,
  hasDateNavigator: PropTypes.bool,
  onNavigateDate: PropTypes.func,
  onAutocompleteClick: PropTypes.func,
  hasAutocomplete: PropTypes.bool,
  onAutoCompleteTyping: PropTypes.func,
  autocompletePlaceholder: PropTypes.string,
  autocompleteSearchText: PropTypes.string,
  autocompleteSuggestions: PropTypes.array,
  autocompleteDescriptionKey: PropTypes.array
}

export default ListTable
