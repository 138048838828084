import { createBrowserHistory } from 'history'
import { createStore, applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import { routerMiddleware } from 'react-router-redux'
import LogRocket from 'logrocket'
import thunk from 'redux-thunk'
import rootReducer from '../reducers'
import token from '../middlewares/token-middleware'

const history = createBrowserHistory()
const historyMiddleware = routerMiddleware(history)

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
  blacklist: ['bookings', 'corporates', 'employees',
    'stats', 'subscriptions', 'lines', 'trips']
}

const middlewares = [
  historyMiddleware,
  token,
  thunk,
  LogRocket.reduxMiddleware()
]

const persistedReducer = persistReducer(persistConfig, rootReducer)

const configStore = (initialState) => {
  const store = createStore(
    persistedReducer,
    initialState,
    applyMiddleware(...middlewares)
  )
  const persistor = persistStore(store)
  return { store, persistor }
}

export default configStore
