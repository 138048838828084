import React from 'react'
import PropTypes from 'prop-types'

const PasswordInput = ({ name, value, onChange, isRequired }) => {
  return (
    <div className='w-100 mt4'>
      <label className='db fw5 mb2 lh-copy f6 tracked' htmlFor='password'>Enter your password</label>
      <input
        className='pa3 input-reset ba bg-transparent w-100'
        value={value}
        name={name}
        onChange={onChange}
        type='password'
        id='password'
        required={isRequired} />
    </div>
  )
}

PasswordInput.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isRequired: PropTypes.bool.isRequired
}

export default PasswordInput
