import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { render } from 'react-dom'

import App from './containers/app'
import configStoreDev from './store/config-store'
import configStoreProd from './store/config-store-prod'
import { attachUser, heartbeat } from './actions/user-actions/user-actions'
import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'
import Raven from 'raven-js'

import './app.scss'

// Sentry in production only
if (window._env_.REACT_APP_ENV === 'production') {
  Raven.config(process.env.REACT_APP_SENTRY_URL).install()
  LogRocket.init(process.env.REACT_APP_LOGROCKET_ID, {
    console: {
      isEnabled: false
    }
  })
  setupLogRocketReact(LogRocket)
}

const { store } = window._env_.REACT_APP_ENV === 'production' ? configStoreProd() : configStoreDev()
store.dispatch(attachUser())
store.dispatch(heartbeat())

render(
  <BrowserRouter>
    <App store={store} />
  </BrowserRouter>,
  document.getElementById('app')
)
