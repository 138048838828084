const urlId = name => {
  if (!name) return null

  return id => {
    if (!id) return null

    return `/${name}/${id}`
  }
}

export default urlId
