/* global Raven */
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import LoadingBar from '../../components/loading-bar/loading-bar'
import PageTile from '../../components/page-tile/page-tile'
import Snackbar from '@material-ui/core/Snackbar'
import Slide from '@material-ui/core/Slide'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'

class PageContainer extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = { isSnakOpen: false }
    this.handleClose = this.handleClose.bind(this)
  }

  componentWillReceiveProps ({ snaks }) {
    const snaksText = snaks
    const isSnakOpen = Boolean(snaks)

    this.setState({ snaksText, isSnakOpen })
  }

  componentDidCatch (error, errorInfo) {
    Raven.captureException(error, { extra: errorInfo })
  }

  handleClose () {
    const isSnakOpen = false
    const snaksText = null

    this.setState({ isSnakOpen, snaksText })
    this.props.snaksOnClose()
  }

  render () {
    try {
      return (
        <Fragment>
          <Helmet>
            <title>{this.props.title}</title>
          </Helmet>
          <Fragment>
            <LoadingBar value={100} />
            <PageTile title={this.props.tileTitle} description={this.props.tileDescription} />
            {this.props.children}
          </Fragment>

          <Slide direction='right'>
            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              action={[
                <IconButton
                  key='close'
                  aria-label='Close'
                  color='inherit'
                  onClick={this.handleClose}
                >
                  <Icon>close</Icon>
                </IconButton>
              ]}
              open={this.state.isSnakOpen}
              autoHideDuration={4000}
              onClose={this.handleClose}
              message={(<span>{this.state.snaksText}</span>)}
            />
          </Slide>

        </Fragment>
      )
    } catch (error) {
      throw (error)
    }
  }
}

PageContainer.propTypes = {
  children: PropTypes.object,
  title: PropTypes.string,
  loaded: PropTypes.bool,
  withBackground: PropTypes.bool,
  tileTitle: PropTypes.string.isRequired,
  tileDescription: PropTypes.string.isRequired,
  snaks: PropTypes.string,
  snaksOnClose: PropTypes.func
}

PageContainer.defaultProps = {
  loaded: false,
  withBackground: false,
  title: 'Business Dashboard'
}

export default PageContainer
