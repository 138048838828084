import { map } from 'ramda'
import moment from 'moment'
import initialState from '../initial-state'
import { bookingActionTypes } from '../../constants/actions'
import { trim } from '../../utils'

const mapBooking = item => ({
  _source: {
    ...item._source,
    ride: {
      ...item._source.ride,
      name: item._source.ride.route.name
    },
    timing: moment(item._source.date).format('hh:MM a'),
    user: {
      ...item._source.user,
      // TODO: hot fix should never return null from backend
      name: (item._source.user) ? trim(item._source.user.name) : ''
    }
  },
  _id: item._id
})

const mapBookings = map(mapBooking)

const bookingReducer = (state = initialState.booking, action) => {
  switch (action.type) {
    case bookingActionTypes.LOAD: {
      let user = state.user
      const { hits, total } = action.bookings
      const bookings = mapBookings(hits)
      const page = action.param && action.param.page ? action.param.page - 1 : state.list.page
      const list = { ...state.list, total, hits: bookings, page }

      if (action.status === 'upcomingBookings') {
        user = { ...user, upcomingBookings: list }
      } else if (action.status === 'pastBookings') {
        user = { ...user, pastBookings: list }
      }

      return { ...state, list, user }
    }
    default:
      return state
  }
}

export default bookingReducer
