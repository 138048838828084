import React from 'react'
import PropTypes from 'prop-types'

const TableTab = ({ title, description, isActive, onTabSwitch }) => {
  const isActiveClassName = isActive ? 'active' : ''

  return (
    <div className='table-tab' onClick={onTabSwitch}>
      <div className={'table-tab-item ' + isActiveClassName}>
        <div className='table-tab-item-title'>{title}</div>
        <div className='table-tab-item-description'>{description}</div>
      </div>
    </div>
  )
}

TableTab.propTypes = {
  isActive: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  onTabSwitch: PropTypes.func
}

export default TableTab
