import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { mapie } from '../../../../utils'
import MapCard from '../../../../components/map-card/map-card'
import ListTable from '../../../../components/list-table/list-table'
import InfoCard, { InfoCardItem } from '../../../../components/info-card/info-card'
import ConfirmDialog from '../../../../components/confirm-dialog/confirm-dialog'
import FormDialog from '../../../../components/form-dialog/form-dialog'
import SelectInput from '../../../../components/select-input/select-input'
import TabListTable, { TableTab } from '../../../../components/tab-list-table/tab-list-table'
import AutocomleteInput from '../../../../components/autocomplete-input/autocomplete-input'

const LineDetailsView = ({ line, tableActiveTab, tableActiveItems,
  tableActiveFields, tableTabs,
  onChangeSubsPage, onRowPerSubsChange, tableActions,
  isCancelDialogOpen, isEditDialogOpen, onCancelConfirm, onCancelClose, onEditConfirm, onEditClose,
  cancelItem, confirmItem, editItem, isAddDialogOpen, confirmAddItem, onAddClose, onAddConfirm, newSubscription,
  addSubscription, onEditSubscriptionChange, onAutoCompleteTyping, autocompletePlaceholder, autocompleteSuggestions,
  autocompleteSearchText, onAutocompleteClick, onAddSubscriptionChange, onDialogClose }) => {
  const districts = line._source.districts.reduce((acc, d) => {
    return acc.length ? `${acc}, ` + d.name : d.name
  }, '')
  const mapData = mapie(line._source.stations)

  const tabs = tableTabs.map((schedule, index) => (
    <TableTab
      isActive={index === tableActiveTab}
      key={schedule._id}
      title={moment(schedule.time).format('hh:MM a')}
      description='12 subscriptions'
    />)
  )

  const table = (
    <ListTable
      hasAdd
      addTooltip='Add Subscription'
      onAddClick={addSubscription}
      hasActions
      theme='light'
      colHeight={82}
      fields={tableActiveFields}
      total={tableActiveItems.total}
      actions={tableActions}
      page={tableActiveItems.page}
      limit={tableActiveItems.limit}
      items={tableActiveItems.hits}
      onPageChange={onChangeSubsPage}
      onRowPerPageChange={onRowPerSubsChange}
    />
  )

  return (
    <div className='ma4' id='line-details'>
      <div className='flex items-center flex-row-l flex-column-m flex-column-ns-override'>
        <div className='flex-column w-50-l ma1 w-100-m  w-100'>
          <InfoCard bordered>
            <InfoCardItem label='Code' value={line._source.name} />
            <InfoCardItem label='Districts' value={districts} />
          </InfoCard>
          <InfoCard>
            <InfoCardItem label='Stations' value={line._source.stations.length} />
            <InfoCardItem label='Distance' value={line._source.city.name} />
          </InfoCard>
        </div>
        <div className='w-50-l w-100-m ma1 h5-l h5-m h5' id='map-card'>
          <MapCard {...mapData} />
        </div>
      </div>

      <div className='mt2 w-100'>
        <TabListTable
          tabs={tabs}
          table={table}
          activeTabIndex={tableActiveTab}
        />
        {
          isCancelDialogOpen &&
          <ConfirmDialog
            title='Are you sure you want to cancel?'
            onClose={onDialogClose('isCancelDialogOpen')}
            description={`
              You are about to cancel subscription for
              ${cancelItem._source.user.name} on ${line._source.name}
              ${moment(line._source.schedules[tableActiveTab].time).format('hh:MM a')}.
              Accordingly bookings for ${cancelItem._source.user.name} will be cancelled.
              You still can add this subscription again.
            `}
            handleClose={onCancelClose}
            handleConfirm={onCancelConfirm}
          />
        }
        {
          isEditDialogOpen &&
          <FormDialog
            title='Edit Subscription'
            error={editItem.error}
            handleClose={onEditClose}
            onClose={onDialogClose('isEditDialogOpen')}
            handleConfirm={onEditConfirm}
          >
            <SelectInput
              options={{ className: 'full-width mb-30' }}
              name='time'
              value={editItem._source.schedule._id}
              title='Timing'
              onChange={onEditSubscriptionChange}
              items={line._source.schedules}
            />
            <SelectInput
              options={{ className: 'full-width mb-30' }}
              name='pick_up'
              value={editItem._source.pick_up._id}
              title='Pick Up Station'
              onChange={onEditSubscriptionChange}
              items={line._source.stations}
            />
            <SelectInput
              options={{ className: 'full-width' }}
              name='drop_off'
              value={editItem._source.drop_off._id}
              title='Drop Off Station'
              onChange={onEditSubscriptionChange}
              items={line._source.stations}
            />
          </FormDialog>
        }
        {
          isAddDialogOpen &&
          <FormDialog
            title='Add New Subscription'
            error={newSubscription.error}
            handleClose={onAddClose}
            onClose={onDialogClose('isAddDialogOpen')}
            handleConfirm={onAddConfirm}
          >
            <AutocomleteInput
              options={{ className: 'full-width mb-30' }}
              onSearch={onAutoCompleteTyping}
              placeholder={autocompletePlaceholder}
              suggestions={autocompleteSuggestions}
              searchText={autocompleteSearchText}
              onItemClick={onAutocompleteClick}
              selectedItem={newSubscription.user}
            />
            <SelectInput
              options={{ className: 'full-width mb-30' }}
              name='time'
              value={newSubscription.time}
              title='Timing'
              onChange={onAddSubscriptionChange}
              items={line._source.schedules}
            />
            <SelectInput
              options={{ className: 'full-width mb-30' }}
              name='pickup'
              value={newSubscription.pickup}
              title='Pick Up Station'
              onChange={onAddSubscriptionChange}
              items={line._source.stations}
            />
            <SelectInput
              options={{ className: 'full-width' }}
              name='dropoff'
              value={newSubscription.dropoff}
              title='Drop Off Station'
              onChange={onAddSubscriptionChange}
              items={line._source.stations}
            />
          </FormDialog>
        }
      </div>
    </div>
  )
}

LineDetailsView.propTypes = {
  line: PropTypes.object.isRequired,
  onChangeSubsPage: PropTypes.func.isRequired,
  onRowPerSubsChange: PropTypes.func.isRequired,
  tableActiveItems: PropTypes.object.isRequired,
  tableActiveTab: PropTypes.number.isRequired,
  tableActiveFields: PropTypes.array.isRequired,
  tableTabs: PropTypes.array.isRequired,
  tableActiveLink: PropTypes.func.isRequired,
  tableActions: PropTypes.array.isRequired,
  isCancelDialogOpen: PropTypes.bool.isRequired,
  isEditDialogOpen: PropTypes.bool.isRequired,
  onCancelConfirm: PropTypes.func.isRequired,
  onCancelClose: PropTypes.func.isRequired,
  confirmItem: PropTypes.object,
  cancelItem: PropTypes.object,
  editItem: PropTypes.object,
  isAddDialogOpen: PropTypes.bool,
  confirmAddItem: PropTypes.object,
  onAddClose: PropTypes.func,
  onAddConfirm: PropTypes.func,
  addSubscription: PropTypes.func,
  onEditSubscriptionChange: PropTypes.func,
  onAddSubscriptionChange: PropTypes.func,
  newSubscription: PropTypes.object,
  onEditConfirm: PropTypes.func,
  onEditClose: PropTypes.func,
  autocompleteSearchText: PropTypes.string,
  onAutoCompleteTyping: PropTypes.func,
  autocompletePlaceholder: PropTypes.string,
  autocompleteSuggestions: PropTypes.array,
  onAutocompleteClick: PropTypes.func,
  onDialogClose: PropTypes.func
}

export default LineDetailsView
