import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { isEmpty } from 'ramda'
import StatsView from './stats-view/stats-view'
import * as statsActions from '../../actions/stats-actions/stats-actions'
import Page from '../page/page'

class HomeContainer extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      stats: props.stats
    }
    this.onNavigateDate = this.onNavigateDate.bind(this)
  }

  componentDidMount () {
    this.getDailyStats()
    this.getGeneralStats()
  }

  componentWillReceiveProps ({ stats }) {
    this.setState({ stats })
  }

  getDailyStats (params) {
    this.props.getDailyStats(params)
  }
  getGeneralStats () {
    this.props.getGeneralStats()
  }

  onNavigateDate (date) {
    this.getDailyStats({ date })
  }

  render () {
    return (
      <Page
        tileTitle='Statistics'
        tileDescription='Monitor Your Performance'
        title='Business Dashboard | Statistics'
      >
        {
          (isEmpty(this.state.stats.daily) || isEmpty(this.state.stats.general))
            ? null
            : <StatsView
              stats={this.state.stats}
              onNavigateDate={this.onNavigateDate}
            />
        }
      </Page>
    )
  }
}

HomeContainer.propTypes = {
  getDailyStats: PropTypes.func.isRequired,
  getGeneralStats: PropTypes.func.isRequired,
  stats: PropTypes.object.isRequired
}

HomeContainer.propTypes = {}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => {
  return {
    getDailyStats: params => dispatch(statsActions.daily(params)),
    getGeneralStats: () => dispatch(statsActions.general())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer)
