import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import { trim } from '../../../utils'

const AutocompleteSuggestions = ({ isOpen, suggestions, descriptionKeys, onItemClick }) => {
  return (
    isOpen ? (
      <Paper className='autocomplete-suggestions' square>
        {
          suggestions.map(suggestion => (
            <MenuItem
              className='autocomplete-suggestions-menu'
              key={suggestion._id}
              component='div'
              onClick={onItemClick(suggestion)}
            >
              <div className='autocomplete-suggestions-menu-name'>
                {trim(suggestion._source.name, 3)}
              </div>
              {
                descriptionKeys &&
                descriptionKeys.map(key => (
                  <div key={key} className='autocomplete-suggestions-menu-description'>{suggestion._source[key]}</div>
                ))
              }
            </MenuItem>
          ))
        }
      </Paper>
    ) : null
  )
}

AutocompleteSuggestions.propTypes = {
  isOpen: PropTypes.bool,
  suggestions: PropTypes.array,
  descriptionKeys: PropTypes.array,
  onItemClick: PropTypes.func.isRequired
}

export default AutocompleteSuggestions
