import { map, isEmpty } from 'ramda'
import moment from 'moment'
import initialState from '../initial-state'
import { phoneFormatter } from '../../utils/'
import { employeeActionTypes, subscriptionActionTypes } from '../../constants/actions'

const mapEmployee = item => ({
  _source: {
    ...item._source,
    phone: phoneFormatter(item._source.phone),
    createdAt: moment(item._source.date).format('MMMM DD, YYYY')
  },
  _id: item._id
})

const mapEmployees = map(mapEmployee)

const employeeReducer = (state = initialState.employee, action) => {
  switch (action.type) {
    case employeeActionTypes.LOAD: {
      const list = { ...state.list, ...action.employees }
      const { hits } = list

      return {
        ...state,
        list: {
          ...list,
          hits: mapEmployees(hits)
        }
      }
    }

    case employeeActionTypes.LOAD_EMPLOYEE: {
      const employee = mapEmployee({ _id: action.employee._id, _source: action.employee })
      const single = { ...employee }

      return { ...state, single }
    }

    case employeeActionTypes.REMOVE_EMPLOYEE: {
      const single = null

      return { ...state, single }
    }

    case employeeActionTypes.AUTOCOMPLETE_EMPLOYEE: {
      const { hits } = action.employees
      const suggestions = mapEmployees(hits)

      return { ...state, suggestions }
    }

    case subscriptionActionTypes.REMOVE_SUBSCRIPTION: {
      if (isEmpty(state.single)) return state

      const totalSubscriptions = state.single._source.total_subscriptions

      return {
        ...state,
        single: {
          ...state.single,
          _source: {
            ...state.single._source,
            total_subscriptions: totalSubscriptions - 1
          }
        }
      }
    }

    case subscriptionActionTypes.ADD_SUBSCRIPTION: {
      if (isEmpty(state.single)) return state

      const totalSubscriptions = state.single._source.total_subscriptions
      return {
        ...state,
        single: {
          ...state.single,
          _source: {
            ...state.single._source,
            total_subscriptions: totalSubscriptions + 1
          }
        }
      }
    }
    default:
      return state
  }
}

export default employeeReducer
