import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import ListAction from '../list-action/list-action'
import { getName } from '../../../utils/'

const ListRow = ({ item, type, fields, linkCreator, colHeight, hasActions, actions }) => {
  return (
    <TableRow
      key={item._id}
      className='table-row table-body-row flex-row'
    >
      {
        fields && fields.length > 0 &&
        fields.map(field => {
          return (
            <TableCell
              className='table-cell'
              key={field.name}
              style={{
                height: `${colHeight}px`
              }}
            >
              {
                linkCreator &&
                <Link className='table-cell-link' to={linkCreator(item._id)} >
                  <div className={`table-cell-content truncate truncate-ns ${field.name === 'name' ? 'ttc' : ''}`}>
                    {
                      (
                        (field.name === 'name' && type === 'route') ||
                        (field.name === 'route' && type === 'trip')
                      ) ? (
                          <div className='flex-column'>
                            <div className='left-bar'>
                              {getName(item._source[field.name]).split('-')[0]}
                            </div>
                            <div className='left-bar'>
                              {getName(item._source[field.name]).split('-')[1]}
                            </div>
                          </div>
                        ) : (
                          getName(item._source[field.name])
                        )
                    }
                  </div>
                </Link>
              }
              {
                !linkCreator &&
                <div className='pl3 pr2 items-center truncate truncate-ns'>
                  {
                    (field.name === 'route' && type === 'user'
                    ) ? (
                        <div className='flex-column'>
                          <div className='left-bar'>
                            {getName(item._source[field.name]).split('-')[0]}
                          </div>
                          <div className='left-bar'>
                            {getName(item._source[field.name]).split('-')[1]}
                          </div>
                        </div>
                      ) : (
                        getName(item._source[field.name])
                      )
                  }
                </div>

              }
            </TableCell>

          )
        })
      }
      {
        actions && actions.length &&
        (<th className='left-0'>
          {
            actions && actions.length
              ? <ListAction
                actions={actions}
                item={item}
              />
              : null
          }
        </th>)
      }
    </TableRow>
  )
}

ListRow.propTypes = {
  item: PropTypes.object.isRequired,
  type: PropTypes.string,
  fields: PropTypes.array.isRequired,
  actions: PropTypes.array,
  linkCreator: PropTypes.func,
  colHeight: PropTypes.number,
  hasActions: PropTypes.bool
}

export default ListRow
