import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Icon from '@material-ui/core/Icon'

class DateNavigator extends React.Component {
  constructor (props) {
    super(props)

    const isSameOrAfter = moment().startOf('day').isSameOrAfter(props.current)
    const current = isSameOrAfter ? moment().startOf('day') : moment(props.current)
    const date = moment(current).startOf('day')
    const formatted = this._format(moment(date))

    this.state = { date, formatted }
    this.goForward = this.goForward.bind(this)
    this.goBackward = this.goBackward.bind(this)
  }

  componentDidMount () {
    this._checkForward(this.state.date)
  }

  _format (date) {
    return date.format('MMM DD, YYYY')
  }
  _apply (date) {
    const formatted = this._format(date)
    const isoDate = date.toISOString()
    this.setState({ date, formatted })
    this.props.onChange(isoDate)
  }

  _checkForward (date) {
    const today = moment().startOf('day')
    const nextDay = moment(date).clone().add(1, 'days')
    const isForwardDisabled = nextDay.isAfter(today)
    this.setState({ isForwardDisabled })
  }

  goForward () {
    if (this.state.isForwardDisabled) return

    const date = moment(this.state.date).add(1, 'days')
    this._apply(date)
    this._checkForward(date)
  }
  goBackward () {
    const date = moment(this.state.date).subtract(1, 'days')
    this._apply(date)
    this._checkForward(date)
  }

  render () {
    return (
      <div className='date-navigator mr4 ml4-ns'>
        <Icon
          className='date-navigator-arrows'
          onClick={this.goBackward}
        >
          keyboard_arrow_left
        </Icon>
        <span className='date-navigator-date'>
          {this.state.formatted}
        </span>
        <Icon
          className={'date-navigator-arrows ' + (this.state.isForwardDisabled ? 'disabled' : '')}
          onClick={this.goForward}
        >
          keyboard_arrow_right
        </Icon>
      </div>
    )
  }
}

DateNavigator.propTypes = {
  onChange: PropTypes.func.isRequired,
  current: PropTypes.object
}

export default DateNavigator
