import initialState from '../initial-state'
import { corporateActionTypes } from '../../constants/actions'

const corporateReducer = (state = initialState.corporate, action) => {
  switch (action.type) {
    case corporateActionTypes.LOAD_CORPORATE: {
      return action.corporate
    }
    default:
      return state
  }
}

export default corporateReducer
