import get from './get'
import post from './post'
import remove from './delete'
import patch from './patch'

export default {
  get,
  post,
  remove,
  patch
}
