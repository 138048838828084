import { createMuiTheme } from '@material-ui/core/styles'

const themeOptions = {
  palette: {
    primary: {
      main: '#ef4349',
      light: '#ff7875',
      dark: '#b50021',
      contrastText: '#FFFFFF'
    },
    secondary: {
      main: '#4a90e2',
      light: '#83c0ff',
      dark: '#0063b0',
      contrastText: '#FFFFFF'
    }
  },
  status: {
    danger: 'orange'
  },
  typography: {
    useNextVariants: true
  }
}

export default createMuiTheme(themeOptions)
