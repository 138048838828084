import entities from '../entities'
import api from '../api'
import * as subscriptionConstants from '../../constants/actions/subscription-actions'

const getSubscriptionSucess = (subscriptions, param) => {
  return { type: subscriptionConstants.LOAD_SUBSCRIPTION, subscriptions: subscriptions.data, param }
}

const removeSubscriptionSucess = (subscription) => {
  return { type: subscriptionConstants.REMOVE_SUBSCRIPTION, subscription: subscription.data }
}

const addSubscriptionSucess = (subscription) => {
  return { type: subscriptionConstants.ADD_SUBSCRIPTION, subscription: subscription.data }
}

const updateSubscriptionSucess = (subscription) => {
  return { type: subscriptionConstants.UPDATE_SUBSCRIPTION, subscription: subscription.data }
}

const addSubscriptionError = (error) => {
  return { type: subscriptionConstants.ADD_SUBSCRIPTION_ERROR, error: error.data }
}

const updateSubscriptionError = (error) => {
  return { type: subscriptionConstants.UPDATE_SUBSCRIPTION_ERROR, error: error.data }
}

const getAll = (param) => {
  return (dispatch) => {
    return api.get(entities.subscription, param)
      .then(subscriptions => {
        dispatch(getSubscriptionSucess(subscriptions, param))
      })
      .catch(error => {
        throw (error)
      })
  }
}

const add = (params) => {
  return (dispatch) => {
    return api.post(entities.subscription, params)
      .then(subscription => {
        dispatch(addSubscriptionSucess(subscription))
      })
      .catch(error => {
        dispatch(addSubscriptionError(error.response))
      })
  }
}

const update = (id, params) => {
  return (dispatch) => {
    return api.patch(entities.subscription + id, params)
      .then(subscription => {
        dispatch(updateSubscriptionSucess(subscription))
      })
      .catch(error => {
        dispatch(updateSubscriptionError(error.response))
      })
  }
}

const remove = (id) => {
  return (dispatch) => {
    return api.remove(entities.subscription + id)
      .then(response => {
        dispatch(removeSubscriptionSucess(response))
      })
      .catch(error => {
        throw (error)
      })
  }
}

export { getAll, add, update, remove }
