import React from 'react'
import PropTypes from 'prop-types'
import { mapie } from '../../../../utils'
import InfoCard, { InfoCardItem } from '../../../../components/info-card/info-card'
import ProfileCard from '../../../../components/profile-card/profile-card'
import ListTable from '../../../../components/list-table/list-table'
import TabListTable, { TableTab } from '../../../../components/tab-list-table/tab-list-table'
import MapCard from '../../../../components/map-card/map-card'

const TripDetailsView = ({ trip, tableActiveTab, tableActiveItems,
  tableActiveFields, tableTabs, trackingLocation, onPageChange, onRowPerPageChange }) => {
  const mapData = mapie([...trip._source.past_stations, ...trip._source.coming_stations])
  const tabs = tableTabs.map((tab, index) => (
    <TableTab
      isActive={index === tableActiveTab}
      key={tab.name}
      title={tab.title}
      description={`${trip._source.bookings.count} items`}
    />)
  )
  const table = (
    <ListTable
      theme='light'
      colHeight={82}
      fields={tableActiveFields}
      total={tableActiveItems.total}
      page={tableActiveItems.page}
      limit={tableActiveItems.limit}
      items={tableActiveItems.hits}
      onPageChange={onPageChange}
      onRowPerPageChange={onRowPerPageChange}
    />
  )

  return (
    <div className='ma4' id='trip-details'>
      <div className='flex items-center flex-row-l flex-column-m flex-column-ns-override'>
        <div className='flex-column w-50-l ma1 w-100-m w-100'>
          <InfoCard bordered>
            <InfoCardItem label='Line' value={trip._source.route.name} />
            <InfoCardItem label='City' value={trip._source.city.name} />
          </InfoCard>
          <InfoCard>
            <InfoCardItem label='Date' value={trip._source.date} />
            { trip._source.bus.make &&
              <InfoCardItem label='Bus' value={`${trip._source.bus.make} ${trip._source.bus.model}, ${trip._source.bus.plates}`} />
            }
          </InfoCard>
        </div>
        <div className='w-50-l w-100-m w-100 ma1 h5-l h5-m h5'>
          <ProfileCard
            actionIcon='phone'
            actionClick={() => {}}
            actionClassName='default-add-background'
            actionTooltip={trip._source.captain.phone}
            avatar={trip._source.captain.picture}
            name={trip._source.captain.name}
            description={trip._source.captain.rating}
          />
        </div>
      </div>

      <div className='w-100 mt2 mb2 h5-override' id='map-card'>
        <MapCard {...mapData} trackingLocation={trackingLocation} tripStatus={trip._source.status} />
      </div>

      <div className='mt3'>
        <TabListTable
          tabs={tabs}
          table={table}
          activeTabIndex={tableActiveTab}
        />
      </div>
    </div>
  )
}

TripDetailsView.propTypes = {
  trip: PropTypes.object.isRequired,
  tableActiveItems: PropTypes.object.isRequired,
  tableActiveTab: PropTypes.number.isRequired,
  tableActiveFields: PropTypes.array.isRequired,
  tableTabs: PropTypes.array.isRequired,
  trackingLocation: PropTypes.object,
  onPageChange: PropTypes.func.isRequired,
  onRowPerPageChange: PropTypes.func.isRequired
}

export default TripDetailsView
