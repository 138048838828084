import * as snakConstants from '../../constants/actions/snaks-actions'

const removeSnakSuccess = () => {
  return { type: snakConstants.REMOVE_SNAK }
}

const removeSnaks = () => {
  return (dispatch) => {
    dispatch(removeSnakSuccess())
  }
}

export { removeSnaks }
