import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import StatCard from '../../../components/stat-card/stat-card'
import DateNavigator from '../../../components/date-navigator/date-navigator'

const StatsView = ({ stats, onNavigateDate }) => {
  return (
    <div className='flex-column' id='stats-view'>
      <Fragment>
        <div className='override flex justify-between flex-column-m'>
          <h3 className='f6 ml4 ttu tracked'>Daily Statistics</h3>
          <DateNavigator
            onChange={onNavigateDate}
          />
        </div>
        <div className='override flex flex-wrap items-center'>
          <StatCard
            title='Trips'
            count={stats.daily.today_rides.count}
          />
          <StatCard
            title='Bookings'
            count={stats.daily.today_booking.count}
          />
          <StatCard
            title='Punctuality'
            className='primary-danger'
            count={stats.daily.punctuality}
          />
          <StatCard
            title='Rating'
            count={stats.daily.today_rating.average.toFixed(2)}
          />
        </div>
      </Fragment>
      <Fragment>
        <div className='flex justify-between'>
          <h3 className='f6 ml4 ttu tracked'>You currently have</h3>
        </div>

        <div className='flex flex-wrap items-center'>
          <StatCard
            title='Users'
            count={stats.general.users_count}
          />
          <StatCard
            title='Lines'
            className='primary-danger'
            count={stats.general.lines_count}
          />
          <StatCard
            title='Timings'
            className='primary-warning'
            count={stats.general.timings_count}
          />
          <StatCard
            title='Subscriptions'
            className='secondary-success'
            count={stats.general.subs_count}
          />
        </div>
      </Fragment>
    </div>
  )
}

StatsView.propTypes = {
  stats: PropTypes.object.isRequired,
  onNavigateDate: PropTypes.func.isRequired
}

export default StatsView
