export const LOGGEDIN_USER = 'LOGGEDIN_USER'
export const ATTACH_USER = 'ATTACH_USER'
export const DETATCH_USER = 'DETATCH_USER'
export const REFRESHING_TOKEN = 'REFRESHING_TOKEN'
export const LOGIN_USER_START = 'LOGIN_USER_START'
export const LOGIN_USER_END = 'LOGIN_USER_END'
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR'
export const LOGOUT_USER_START = 'LOGOUT_USER_START'
export const LOGOUT_USER_END = 'LOGOUT_USER_END'
export const LOGOUT_USER_ERROR = 'LOGOUT_USER_ERROR'
export const LOGGEDOUT_USER = 'LOGGEDOUT_USER'
