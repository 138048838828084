import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

class ListAction extends React.Component {
  constructor (props, context) {
    super(props, context)
    this.state = { anchorEl: null }
    this.handleClick = this.handleClick.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  handleClick (event) {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose () {
    this.setState({ anchorEl: null })
  }

  handleItemClick (method, param) {
    return () => {
      method(param)
      this.setState({ anchorEl: null })
    }
  }

  render () {
    return (
      <Fragment>
        <IconButton
          className='mt3'
          aria-label='More'
          aria-haspopup='true'
          onClick={this.handleClick}
        >
          <Icon>more_vert</Icon>
        </IconButton>
        <Menu
          className=''
          anchorEl={this.state.anchorEl}
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleClose}
        >
          {
            this.props.actions && this.props.actions.length &&
            (
              this.props.actions.map(action => (
                <MenuItem
                  className=''
                  key={action.name}
                  onClick={this.handleItemClick(action.method, this.props.item)}
                >
                  {
                    action.icon &&
                    <Icon className=''>{action.icon}</Icon>
                  }
                  {
                    action.title
                  }
                </MenuItem>
              ))
            )
          }
        </Menu>
      </Fragment>
    )
  }
}

ListAction.propTypes = {
  actions: PropTypes.array.isRequired,
  item: PropTypes.object.isRequired
}

export default ListAction
