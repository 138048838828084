import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'

const SubmitButton = ({ text, isDisabled, onSubmit, isLoading }) => {
  return (
    <Button
      className='w-100 pointer mt4 mb4 f6 link dim br3 ph3 pv2 mb2 dib'
      type='submit'
      variant='contained'
      color='primary'
      disabled={isDisabled}
      onClick={onSubmit}
      id='submit-button'
    >
      {isLoading ? (
        <Icon className='submit-button-loading mt2'>autorenew</Icon>
      ) : (text || 'Submit')}
    </Button>
  )
}

SubmitButton.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  text: PropTypes.string
}

export default SubmitButton
