import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { urlId } from '../../utils'
import * as lineActions from '../../actions/line-actions/line-actions'
import lineFields from '../../constants/fields/line-fields'
import ListTable from '../../components/list-table/list-table'
import Page from '../page/page'

const lineUrl = urlId('lines')

class LinesContainer extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.params = {}
    this.state = { page: this.props.lines.list.page, limit: this.props.lines.list.limit }
    this.onPageChange = this.onPageChange.bind(this)
    this.onRowPerPageChange = this.onRowPerPageChange.bind(this)
  }

  componentDidMount () {
    this.fetchLines()
  }

  fetchLines () {
    this.props.actions.load()
  }

  onPageChange (e, page) {
    if (!e) return
    this.params = Object.assign({}, this.params, { page: page + 1 })
    this.props.actions.load(this.params)
    this.setState({ page: page + 1 })
  }

  onRowPerPageChange (e) {
    if (!e) return
    const limit = e.target.value

    this.params = Object.assign({}, this.params, { limit })
    this.props.actions.load(this.params)
    this.setState({ limit })
  }

  render () {
    return (
      <Page
        withBackground
        tileTitle='Lines'
        tileDescription='Lines, Schedules and Subscriptions'
        title='Business Dashboard | Lines'
      >
        <div className='ma4'>
          <ListTable
            title='Lines'
            colHeight={70}
            linkCreator={lineUrl}
            fields={lineFields}
            total={this.props.lines.list.total}
            page={this.state.page}
            limit={this.state.limit}
            items={this.props.lines.list.hits}
            type={this.props.lines.list.type}
            onPageChange={this.onPageChange}
            onRowPerPageChange={this.onRowPerPageChange}
          />
        </div>

      </Page>
    )
  }
}

LinesContainer.propTypes = {
  actions: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  lines: PropTypes.object.isRequired
}

const mapStateToProps = (state, ownProp) => {
  return Object.assign({}, state, { match: ownProp.match })
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(lineActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LinesContainer)
