import entities from '../entities'
import api from '../api'
import * as userConstants from '../../constants/actions/user-actions'
import { push, replace } from 'react-router-redux'
import LogRocket from 'logrocket'

const loginUserStart = () => {
  return { type: userConstants.LOGIN_USER_START }
}

const loginUserError = error => {
  return { type: userConstants.LOGIN_USER_ERROR, error: error.data }
}

const loginUserEnd = () => {
  return { type: userConstants.LOGIN_USER_END }
}

const loginUserSuccess = user => {
  return { type: userConstants.LOGGEDIN_USER, user }
}

const attachUserSucess = user => {
  return { type: userConstants.ATTACH_USER, user: user }
}

const detatchUser = () => {
  return { type: userConstants.DETATCH_USER }
}

const refreshingToken = refreshingPromise => {
  return { type: userConstants.REFRESHING_TOKEN, refreshingPromise }
}

const logoutUserStart = () => {
  return { type: userConstants.LOGOUT_USER_START }
}

const logoutUserError = error => {
  return { type: userConstants.LOGOUT_USER_ERROR, error: error.data }
}

const logoutUserEnd = () => {
  return { type: userConstants.LOGOUT_USER_END }
}

const logoutUserSuccess = (user) => {
  return { type: userConstants.LOGGEDOUT_USER, user }
}

const loginUser = user => {
  return dispatch => {
    dispatch(loginUserStart())
    return api.post(entities.login, user)
      .then(response => {
        dispatch(loginUserSuccess(response.data))
      }).catch(error => {
        dispatch(loginUserError(error.response))
      }).then(() => {
        dispatch(loginUserEnd())
      })
  }
}

const logoutUser = (token) => {
  const clearStorage = () => localStorage.clear()

  return dispatch => {
    dispatch(logoutUserStart())
    return api.post(entities.signout, token)
      .then(response => {
        clearStorage()
        dispatch(logoutUserSuccess(response.data))
        dispatch(detatchUser())
        dispatch(replace('/login'))
      })
      .catch(error => {
        dispatch(logoutUserError(error))
      })
      .then(() => {
        dispatch(logoutUserEnd())
      })
  }
}

const refreshToken = (token) => {
  return (dispatch) => {
    const request = api.post(entities.refreshToken, token)
    dispatch(refreshingToken(request))

    request
      .then(response => {
        authenticateUser(response.data)()
        dispatch(loginUserSuccess(response.data))
      })
      .catch(() => {
        localStorage.removeItem('auth')

        dispatch(detatchUser())
        dispatch(push('/login'))
      })

    return request
  }
}

const attachUser = () => {
  return (dispatch) => {
    const userData = localStorage.getItem('auth')
    const user = JSON.parse(userData)

    if (!user) {
      dispatch(detatchUser())
      dispatch(push('/login'))
    } else {
      LogRocket.identify(user.user._id, {
        name: user.user.name,
        email: user.user.email
      })

      dispatch(attachUserSucess(user))
    }
  }
}

const heartbeat = () => {
  return (dispatch) => {
    return api.post(entities.heartbeat)
      .then(() => {
        const user = JSON.parse(localStorage.getItem('auth'))
        dispatch(attachUserSucess(user))
      })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          dispatch(detatchUser())
          dispatch(push('/login'))
        }
      })
  }
}

const authenticateUser = user => {
  return () => {
    localStorage.setItem('auth', JSON.stringify(user))
  }
}

const isExpired = expiry => {
  const currentTime = new Date()
  const expiryDate = new Date(expiry)

  return currentTime > expiryDate
}

export { loginUser, logoutUser, refreshToken, attachUser, heartbeat, authenticateUser, isExpired }
