import React from 'react'
import PropTypes from 'prop-types'
import defaultAvatar from '../../images/avatar.png'
import Icon from '@material-ui/core/Icon'
import Tooltip from '@material-ui/core/Tooltip'
import { trim } from '../../utils'

const formatName = name => {
  const nameWords = name.split(' ')
  const hasMoreThanTwo = nameWords.length > 3

  return hasMoreThanTwo ? { name: `${trim(name)}..`, fullName: name } : { name }
}

const ProfileCard = ({ avatar, name, description, actionIcon, actionOnClick, actionClassName, actionTooltip }) => {
  const formattedName = formatName(name)
  return (
    <div className='h-100 pa4 bg-white '>
      {
        actionIcon &&
        <Tooltip title={actionTooltip} placement='right'>
          <div className={('w2 h2 lh-title-override dib white absolute shadow-2 pointer tc br-100 ' + actionClassName)} onClick={actionOnClick}>
            <Icon>{actionIcon}</Icon>
          </div>
        </Tooltip>
      }

      <div className='profile-card-avatar'>
        <div className='profile-card-avatar-img' style={{ backgroundImage: `url('${avatar || defaultAvatar}')` }} />
      </div>

      {
        formattedName.fullName &&
        <Tooltip className='close-top-tooltip' title={formattedName.fullName} placement='top'>
          <div className='profile-card-name ttc'>{formattedName.name}</div>
        </Tooltip>
      }
      {
        !formattedName.fullName &&
        <div className='profile-card-name ttc'>{formattedName.name}</div>
      }

      {description && <div className='profile-card-description'>{description}</div>}
    </div>
  )
}

ProfileCard.propTypes = {
  name: PropTypes.string.isRequired,
  avatar: PropTypes.string,
  description: PropTypes.string,
  actionIcon: PropTypes.string,
  actionOnClick: PropTypes.func,
  actionClassName: PropTypes.string,
  actionTooltip: PropTypes.string
}

export default ProfileCard
