import entities from '../entities'
import api from '../api'
import * as bookingConstants from '../../constants/actions/booking-actions'
import { startLoading, endLoading } from '../loading'

const loadSucess = (bookings, status, param) => {
  return { type: bookingConstants.LOAD, bookings: bookings.data, status, param }
}

const load = (params, status) => {
  return (dispatch) => {
    dispatch(startLoading())

    return api.get(entities.bookings, params)
      .then(bookings => {
        dispatch(loadSucess(bookings, status, params))
      })
      .catch(error => {
        throw (error)
      })
      .then(() => {
        dispatch(endLoading())
      })
  }
}

export { load }
