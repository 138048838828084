import React from 'react'
import { NavLink } from 'react-router-dom'
import { Button } from '@material-ui/core'

const toggleNav = event => {
  const sidebar = document.getElementById('sidebar')
  event.preventDefault()
  sidebar.classList.toggle('transform-off')
}

const Sidenav = () => {
  return (
    <nav className='flex justify-around items-center h3 fixed br-1' id='sidenav'>
      <div className='flex pa3 items-center transition-transform dn-l' id='hamburger'>
        <Button
          className='f4 near-black w3 dim mr3 mr4-ns rotate-90'
          onClick={toggleNav}
        >
          |||
        </Button>
      </div>
      <div
        className='flex-l justify-around items-start h-100 h8 transition-transform collabsable mt6-ns mt0-l mt6' id='sidebar' >
        <NavLink exact to='/' className='sidenav-link' activeClassName='sidenav-link-is-active'>
          <span className='near-black icon-stats' />
        </NavLink>
        <NavLink to='/employees' className='sidenav-link' activeClassName='sidenav-link-is-active'>
          <span className='near-black  icon-people' />
        </NavLink>
        <NavLink to='/lines' className='sidenav-link' activeClassName='sidenav-link-is-active'>
          <span className='near-black  icon-route' />
        </NavLink>
        <NavLink to='/trips' className='sidenav-link' activeClassName='sidenav-link-is-active'>
          <span className='near-black  icon-trip' />
        </NavLink>
      </div>
    </nav>
  )
}

Sidenav.propTypes = {}

export default Sidenav
