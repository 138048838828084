import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'

const TextInput = ({ label, value, type, name, onTyping, placeholder, options }) => {
  let changeMethods
  if (options) {
    const { onChange } = options

    // eslint-disable-next-line
    changeMethods = e => (onTyping(e), onChange(e));
  }

  return (
    <TextField
      {...options}
      id={name}
      type={(type || 'text')}
      value={value}
      label={label}
      placeholder={placeholder}
      onChange={changeMethods}
    />
  )
}

TextInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  options: PropTypes.object,
  onTyping: PropTypes.func.isRequired
}

TextInput.defaultProps = {
  type: 'text'
}

export default TextInput
