import React from 'react'
import PropTypes from 'prop-types'
import InfoCard, { InfoCardItem } from '../../../../components/info-card/info-card'
import ProfileCard from '../../../../components/profile-card/profile-card'
import ListTable from '../../../../components/list-table/list-table'
import TabListTable, { TableTab } from '../../../../components/tab-list-table/tab-list-table'
import ConfirmDialog from '../../../../components/confirm-dialog/confirm-dialog'
import SelectInput from '../../../../components/select-input/select-input'
import FormDialog from '../../../../components/form-dialog/form-dialog'
import moment from 'moment'

const EmployeeDetailsView = ({ employee, lines, tableActiveTab, tableActiveItems, tableActiveFields,
  tableTabs, tableActions, onTabSwitch, onRemoveUserClick, addSubscription,
  isAddDialogOpen, newSubscription, onAddClose, onAddConfirm,
  selectedLine, onSubscriptionChange, isCancelDialogOpen, cancelItem, onCancelClose,
  onCancelConfirm, isEditDialogOpen, onEditClose, onEditConfirm, editItem, onEditChange,
  isRemoveUserDialogOpen, onRemoveClose, onRemoveConfirm, onTabPageChange, onTabRowPerPageChange, onDialogClose }) => {
  let tabs, table

  if (tableTabs) {
    tabs = tableTabs.map((tab, index) => (
      <TableTab
        isActive={index === tableActiveTab}
        key={tab.name}
        title={tab.title}
        description={`${employee._source[tab.descriptionKey]} items`}
        onTabSwitch={onTabSwitch(tab.name)}
      />)
    )
    table = (
      <ListTable
        hasAdd={tableTabs[tableActiveTab].hasAdd}
        hasActions
        onAddClick={addSubscription}
        actions={tableActions}
        theme='light'
        colHeight={82}
        fields={tableActiveFields}
        type='user'
        total={tableActiveItems.total}
        page={tableActiveItems.page}
        limit={tableActiveItems.limit}
        items={tableActiveItems.hits}
        onPageChange={onTabPageChange(tableActiveTab)}
        onRowPerPageChange={onTabRowPerPageChange(tableActiveTab)}
        addTooltip='Add Subscription'
      />
    )
  }

  return (
    <div className='ma4' id='employee-details'>
      <div className='flex items-center flex-row-l flex-column-m flex-column-ns-override'>
        <div className='flex-column w-100 ma2-l h5'>
          <ProfileCard
            actionIcon='delete'
            actionOnClick={onRemoveUserClick}
            actionClassName='danger-add-background'
            actionTooltip='Remove Employee'
            avatar={employee._source.picture}
            name={employee._source.name}
            description={employee._source.createdAt}
          />
        </div>
        <div className='w-100'>
          <InfoCard bordered>
            <InfoCardItem label='Email' value={employee._source.email} />
          </InfoCard>
          <InfoCard>
            <InfoCardItem label='Phone' value={employee._source.phone} />
          </InfoCard>
        </div>
      </div>

      <div className='mt-30'>
        <TabListTable
          tabs={tabs}
          table={table}
          activeTabIndex={tableActiveTab}
        />

        {
          isAddDialogOpen &&
            <FormDialog
              title='Add Subscription'
              error={newSubscription.error}
              onClose={onDialogClose('isAddDialogOpen')}
              handleClose={onAddClose}
              handleConfirm={onAddConfirm}
            >
              <SelectInput
                options={{ className: 'full-width mb-30' }}
                name='line'
                value={newSubscription.line}
                title='Line'
                onChange={onSubscriptionChange}
                items={lines}
              />
              <SelectInput
                options={{ className: 'full-width mb-30' }}
                name='time'
                value={newSubscription.time}
                title='Timing'
                onChange={onSubscriptionChange}
                items={newSubscription.selectedLine && newSubscription.selectedLine._source.schedules}
              />
              <SelectInput
                options={{ className: 'full-width mb-30' }}
                name='pickup'
                value={newSubscription.pickup}
                title='Pick Up Station'
                onChange={onSubscriptionChange}
                items={newSubscription.selectedLine && newSubscription.selectedLine._source.stations}
              />
              <SelectInput
                options={{ className: 'full-width' }}
                name='dropoff'
                value={newSubscription.dropoff}
                title='Drop Off Station'
                onChange={onSubscriptionChange}
                items={newSubscription.selectedLine && newSubscription.selectedLine._source.stations}
              />
            </FormDialog>
        }
        {
          isCancelDialogOpen &&
            <ConfirmDialog
              title='Are you sure you want to cancel?'
              onClose={onDialogClose('isCancelDialogOpen')}
              description={`
                You are about to cancel subscription for
                ${employee._source.name} on ${cancelItem._source.route.name}
                ${moment(cancelItem._source.schedule.time).format('hh:MM a')}.
                Accordingly bookings for ${employee._source.name} will be cancelled.
                You still can add this subscription again.
              `}
              handleClose={onCancelClose}
              handleConfirm={onCancelConfirm}
            />
        }
        {
          isEditDialogOpen &&
            <FormDialog
              title='Edit Subscription'
              onClose={onDialogClose('isEditDialogOpen')}
              error={editItem.error}
              handleClose={onEditClose}
              handleConfirm={onEditConfirm}
            >
              <SelectInput
                options={{ className: 'full-width mb-30' }}
                name='line'
                value={editItem._source.route._id}
                title='Line'
                onChange={onEditChange}
                items={lines}
              />
              <SelectInput
                options={{ className: 'full-width mb-30' }}
                name='schedule'
                value={editItem._source.schedule._id}
                title='Timing'
                onChange={onEditChange}
                items={editItem._source.schedules}
              />
              <SelectInput
                options={{ className: 'full-width mb-30' }}
                name='pick_up'
                value={editItem._source.pick_up._id}
                title='Pick Up Station'
                onChange={onEditChange}
                items={editItem._source.route.stations}
              />
              <SelectInput
                options={{ className: 'full-width' }}
                name='drop_off'
                value={editItem._source.drop_off._id}
                title='Drop Off Station'
                onChange={onEditChange}
                items={editItem._source.route.stations}
              />
            </FormDialog>
        }

        {
          isRemoveUserDialogOpen &&
            <ConfirmDialog
              title='Are you sure you want to remove?'
              description={`
                You are about to remove ${employee._source.name}
                from your corporate.
                Accordingly all ${employee._source.name} data will be removed.
              `}
              handleClose={onRemoveClose}
              handleConfirm={onRemoveConfirm}
            />
        }
      </div>
    </div>
  )
}

EmployeeDetailsView.propTypes = {
  employee: PropTypes.object.isRequired,
  lines: PropTypes.array,
  newSubscription: PropTypes.object,
  tableActiveItems: PropTypes.object.isRequired,
  tableActiveTab: PropTypes.number.isRequired,
  tableActiveFields: PropTypes.array.isRequired,
  tableTabs: PropTypes.array.isRequired,
  tableActions: PropTypes.array,
  onTabSwitch: PropTypes.func,
  onRemoveUserClick: PropTypes.func,
  addSubscription: PropTypes.func,
  isAddDialogOpen: PropTypes.bool,
  isRemoveUserDialogOpen: PropTypes.bool,
  onAddClose: PropTypes.func,
  onAddConfirm: PropTypes.func,
  selectedLine: PropTypes.object,
  onSubscriptionChange: PropTypes.func,
  isCancelDialogOpen: PropTypes.bool,
  cancelItem: PropTypes.object,
  onCancelClose: PropTypes.func,
  onCancelConfirm: PropTypes.func,
  isEditDialogOpen: PropTypes.bool,
  onEditClose: PropTypes.func,
  onEditConfirm: PropTypes.func,
  editItem: PropTypes.object,
  onEditChange: PropTypes.func,
  onRemoveClose: PropTypes.func,
  onRemoveConfirm: PropTypes.func,
  onTabPageChange: PropTypes.func,
  onTabRowPerPageChange: PropTypes.func,
  onDialogClose: PropTypes.func
}

export default EmployeeDetailsView
