import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty, findIndex, find } from 'ramda'
import { connect } from 'react-redux'
import EmployeeDetailsView from './employee-details-view/employee-details-view'
import Page from '../../page/page'
import userSubscriptionFields from '../../../constants/fields/user-subscription-fields'
import userBookingFields from '../../../constants/fields/user-booking-fields'
import * as employeeActions from '../../../actions/employee-actions/employee-actions'
import * as subscriptionsActions from '../../../actions/subscription-actions/subscription-actions'
import * as bookingActions from '../../../actions/booking-actions/booking-actions'
import * as lineActions from '../../../actions/line-actions/line-actions'
import { removeSnaks } from '../../../actions/snaks-actions/snaks-actions'
import employeeTabs from '../../../constants/tabs/employee-tabs'
import employeeMessages from '../../../constants/messages/employee-message'

class EmployeeDetailsContainer extends React.Component {
  constructor (props, context) {
    super(props, context)
    this.subscriptionActions = [
      {
        name: 'editSubs',
        title: 'Edit',
        icon: 'edit',
        method: this.onEditSubscription.bind(this)
      },
      {
        name: 'cancelSubs',
        title: 'Cancel',
        icon: 'close',
        method: this.onCancelSubscription.bind(this)
      }
    ]

    this.newSubscription = { pickup: '', dropoff: '', time: '', line: '' }
    this.tableFields = {
      subscriptions: userSubscriptionFields,
      upcomingBookings: userBookingFields,
      pastBookings: userBookingFields
    }
    this.state = {
      tableActiveTab: 0,
      tableActiveFields: userSubscriptionFields,
      tableActiveItems: {},
      tableActiveParams: {},
      selectedLine: {},
      lineSuggestions: [],
      lineSearchText: '',
      tableActions: this.subscriptionActions,
      newSubscription: this.newSubscription
    }

    this.onAddClose = this.onAddClose.bind(this)
    this.onTabSwitch = this.onTabSwitch.bind(this)
    this.onAddConfirm = this.onAddConfirm.bind(this)
    this.addSubscription = this.addSubscription.bind(this)
    this.onSubscriptionChange = this.onSubscriptionChange.bind(this)
    this.onCancelClose = this.onCancelClose.bind(this)
    this.onCancelConfirm = this.onCancelConfirm.bind(this)
    this.onEditClose = this.onEditClose.bind(this)
    this.onEditConfirm = this.onEditConfirm.bind(this)
    this.onEditChange = this.onEditChange.bind(this)
    this.onRemoveUser = this.onRemoveUser.bind(this)
    this.onRemoveClose = this.onRemoveClose.bind(this)
    this.onRemoveConfirm = this.onRemoveConfirm.bind(this)
    this.onTabPageChange = this.onTabPageChange.bind(this)
    this.onTabRowPerPageChange = this.onTabRowPerPageChange.bind(this)
    this.onSnacksClose = this.onSnacksClose.bind(this)
    this.onDialogClose = this.onDialogClose.bind(this)
  }

  componentDidMount () {
    const employeeId = this.props.match.params.id

    this.get(employeeId)
    this.getSubscriptions({ user: employeeId })
    this.getLines({ limit: 50 })
  }

  componentWillReceiveProps ({ employees, subscriptions, bookings, lines }) {
    this.setState({ employee: employees.single, bookings: bookings })
    if (subscriptions) this.setState({ subscriptions: subscriptions })
    if (lines) this.setState({ lines: lines.list.hits })
    if (subscriptions && subscriptions.addError) {
      const newSubscription = Object.assign({}, this.state.newSubscription, { error: subscriptions.addError })

      this.setState({ newSubscription })
    }

    if (subscriptions && subscriptions.newlyAdded) {
      const isAddDialogOpen = false
      const snaks = employeeMessages.addSubscription

      this.setState({ isAddDialogOpen, newSubscription: this.newSubscription, snaks })
    }

    if (subscriptions && subscriptions.newlyUpdated) {
      const snaks = employeeMessages.updateSubscription
      const isEditDialogOpen = false

      this.setState({ isEditDialogOpen, newSubscription: this.newSubscription, snaks })
    }

    if (subscriptions && subscriptions.newlyRemoved) {
      const snaks = employeeMessages.removeSubscription
      this.setState({ snaks })
    }

    if (subscriptions && subscriptions.updateError) {
      const editItem = Object.assign({}, this.state.editItem, { error: subscriptions.updateError })

      this.setState({ editItem })
    }

    if (employees.single === null) {
      this.props.history.push('/employees')
    }

    const activeTabIndex = this.state.tableActiveTab
    const activeTab = employeeTabs[activeTabIndex]
    const activeTabName = activeTab.name

    if (activeTabName === 'upcomingBookings') {
      this.setState({ tableActiveItems: bookings.user.upcomingBookings, tableActions: null })
    } else if (activeTabName === 'pastBookings') {
      this.setState({ tableActiveItems: bookings.user.pastBookings, tableActions: null })
    } else if (activeTabName === 'subscriptions') {
      this.setState({ tableActiveItems: subscriptions.list, tableActions: this.subscriptionActions })
    }
  }

  get (id) {
    this.props.get(id)
  }

  getLines (params) {
    this.props.getLines(params)
  }

  onTabSwitch (name) {
    return () => {
      const employeeId = this.props.match.params.id
      const findNextTab = tab => tab.name === name
      const nextTabIndex = findIndex(findNextTab)(employeeTabs)
      const nextTabFields = this.tableFields[name]

      if (name === 'upcomingBookings') {
        this.getUpcomingBookings({ user: employeeId })
      } else if (name === 'pastBookings') {
        this.getPastBookings({ user: employeeId })
      } else if (name === 'subscriptions') {
        this.getPastBookings({ user: employeeId })
      }

      this.setState({ tableActiveTab: nextTabIndex, tableActiveFields: nextTabFields })
    }
  }

  onAddClose () {
    this.setState({ isAddDialogOpen: false, newSubscription: this.newSubscription })
  }
  onAddConfirm () {
    const params = {
      user: this.state.employee._id,
      schedule: this.state.newSubscription.time,
      pick_up: this.state.newSubscription.pickup,
      drop_off: this.state.newSubscription.dropoff
    }

    this.props.addSubscription(params)
  }

  addSubscription () {
    this.setState({ isAddDialogOpen: true, newSubscription: this.newSubscription })
  }

  getSubscriptions (param) {
    this.props.getSubscriptions(param)
  }

  getUpcomingBookings (params) {
    this.props.getBookings({ status: 'booked', ...params }, 'upcomingBookings')
  }

  getPastBookings (params) {
    this.props.getBookings({ status: 'completed', ...params }, 'pastBookings')
  }

  onSubscriptionChange (e) {
    const key = e.target.name
    const value = e.target.value

    if (key === 'line') {
      const selectedLine = this.state.lines.find(line => line._id === value)
      const newSubscription = Object.assign({}, this.state.newSubscription, { [key]: value, selectedLine })
      this.setState({ newSubscription })
    } else {
      const newSubscription = Object.assign({}, this.state.newSubscription, { [key]: value })
      this.setState({ newSubscription })
    }
  }

  onEditSubscription (item) {
    const findFromList = i => i._id === item._source.route._id
    const line = find(findFromList)(this.state.lines)
    const schedules = line._source.schedules
    const editItem = {
      _id: item._id,
      _source: {
        ...item._source,
        schedules
      }
    }

    this.setState({ isEditDialogOpen: true, editItem })
  }

  onCancelSubscription (item) {
    this.setState({ isCancelDialogOpen: true, cancelItem: item })
  }

  onCancelClose () {
    this.setState({ isCancelDialogOpen: false, cancelItem: null })
  }
  onCancelConfirm () {
    const id = this.state.cancelItem._id
    this.props.removeSubscriptions(id)
    this.setState({ isCancelDialogOpen: false, cancelItem: null })
  }

  onEditChange (e) {
    const key = e.target.name
    const value = e.target.value

    if (key === 'line') {
      const selectedLine = this.state.lines.find(line => line._id === value)
      const editItem = {
        _id: this.state.editItem._id,
        _source: {
          ...this.state.editItem._source,
          route: {
            _id: selectedLine._id,
            ...selectedLine._source
          },
          schedule: {
            _id: ''
          },
          schedules: [...selectedLine._source.schedules],
          pick_up: {
            _id: ''
          },
          drop_off: {
            _id: ''
          }
        }
      }
      this.setState({ editItem })
    } else {
      const _source = Object.assign({}, this.state.editItem._source, { [key]: { _id: value } })
      const editItem = Object.assign({}, this.state.editItem, { _source })
      this.setState({ editItem })
    }
  }
  onEditClose () {
    this.setState({ isEditDialogOpen: false, editItem: null })
  }
  onEditConfirm () {
    const user = this.state.employee._id
    const id = this.state.editItem._id
    const pickup = this.state.editItem._source.pick_up._id
    const dropoff = this.state.editItem._source.drop_off._id
    const schedule = this.state.editItem._source.schedule._id

    const params = {
      user,
      pick_up: pickup,
      drop_off: dropoff,
      schedule
    }
    this.props.updateSubscription(id, params)
  }

  onRemoveUser () {
    this.setState({ isRemoveUserDialogOpen: true })
  }

  onRemoveClose () {
    this.setState({ isRemoveUserDialogOpen: false })
  }
  onRemoveConfirm () {
    const id = this.state.employee._id

    this.props.removeEmployee(id)
  }

  onTabPageChange (activeTabIndex) {
    return (e, page) => {
      const tableActiveParams = { ...this.state.tableActiveParams, page: page + 1 }

      this._requestActiveTab(activeTabIndex, tableActiveParams)
      this.setState({ tableActiveParams })
    }
  }

  onTabRowPerPageChange (activeTabIndex) {
    return (e) => {
      if (!e) return
      const limit = e.target.value
      const tableActiveParams = { ...this.state.tableActiveParams, limit }

      this._requestActiveTab(activeTabIndex, tableActiveParams)
      this.setState({ tableActiveParams })
    }
  }

  _requestActiveTab (activeTabIndex, params) {
    const employeeId = this.props.match.params.id
    const activeTab = employeeTabs[activeTabIndex]
    const activeTabName = activeTab.name

    if (activeTabName === 'upcomingBookings') {
      this.getUpcomingBookings({ user: employeeId, ...params })
    } else if (activeTabName === 'pastBookings') {
      this.getPastBookings({ user: employeeId, ...params })
    } else if (activeTabName === 'subscriptions') {
      this.getPastBookings({ user: employeeId, ...params })
    }
  }

  onDialogClose (key) {
    return () => {
      this.setState({ [key]: false })
    }
  }

  onSnacksClose () {
    this.setState({ snaks: null })
    this.props.removeSnaks()
  }

  render () {
    if (!this.state.employee || isEmpty(this.state.employee) || isEmpty(this.state.tableActiveItems)) return null

    return (
      <Page
        tileTitle='people'
        snaks={this.state.snaks}
        snaksOnClose={this.onSnacksClose}
        tileDescription='Manage your employees using our service'
        title={'Business Dashboard | ' + this.state.employee._source.name}
      >
        <EmployeeDetailsView
          employee={this.state.employee}
          tableTabs={employeeTabs}
          tableActiveTab={this.state.tableActiveTab}
          tableActiveItems={this.state.tableActiveItems}
          tableActiveFields={this.state.tableActiveFields}
          tableActions={this.state.tableActions}
          onTabSwitch={this.onTabSwitch}

          isAddDialogOpen={this.state.isAddDialogOpen}
          addSubscription={this.addSubscription}
          onAddClose={this.onAddClose}
          onAddConfirm={this.onAddConfirm}
          newSubscription={this.state.newSubscription}

          lines={this.state.lines}
          onSubscriptionChange={this.onSubscriptionChange}
          isCancelDialogOpen={this.state.isCancelDialogOpen}
          isEditDialogOpen={this.state.isEditDialogOpen}
          onEditChange={this.onEditChange}
          cancelItem={this.state.cancelItem}
          editItem={this.state.editItem}
          onCancelClose={this.onCancelClose}
          onCancelConfirm={this.onCancelConfirm}
          onEditClose={this.onEditClose}
          onEditConfirm={this.onEditConfirm}
          onRemoveUserClick={this.onRemoveUser}
          onDialogClose={this.onDialogClose}
          isRemoveUserDialogOpen={this.state.isRemoveUserDialogOpen}
          onRemoveClose={this.onRemoveClose}
          onRemoveConfirm={this.onRemoveConfirm}
          onTabPageChange={this.onTabPageChange}
          onTabRowPerPageChange={this.onTabRowPerPageChange}
        />
      </Page>
    )
  }
}

EmployeeDetailsContainer.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  get: PropTypes.func.isRequired,
  removeEmployee: PropTypes.func.isRequired,
  removeSubscriptions: PropTypes.func.isRequired,
  addSubscription: PropTypes.func.isRequired,
  updateSubscription: PropTypes.func.isRequired,
  getSubscriptions: PropTypes.func.isRequired,
  getBookings: PropTypes.func.isRequired,
  employees: PropTypes.object.isRequired,
  lines: PropTypes.object.isRequired,
  subscriptions: PropTypes.object.isRequired,
  bookings: PropTypes.object.isRequired,
  upComingBookings: PropTypes.object,
  pastBookings: PropTypes.object,
  getLines: PropTypes.func,
  removeSnaks: PropTypes.func
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => {
  return {
    get: id => dispatch(employeeActions.get(id)),
    removeEmployee: id => dispatch(employeeActions.remove(id)),
    getSubscriptions: param => dispatch(subscriptionsActions.getAll(param)),
    removeSubscriptions: id => dispatch(subscriptionsActions.remove(id)),
    addSubscription: param => dispatch(subscriptionsActions.add(param)),
    updateSubscription: (id, param) => dispatch(subscriptionsActions.update(id, param)),
    getBookings: (param, status) => dispatch(bookingActions.load(param, status)),
    getLines: params => dispatch(lineActions.load(params)),
    removeSnaks: () => dispatch(removeSnaks())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeDetailsContainer)
