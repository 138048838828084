import React from 'react'
import PropTypes from 'prop-types'

const InfoCardItem = ({ label, value }) => {
  return (
    <div className='flex-auto w-50-l w-100 ma1'>
      <div className='tracked ttc mb2 fw4'>{label}</div>
      <div className='mb2 fw2'>{value}</div>
    </div>
  )
}

InfoCardItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default InfoCardItem
