import React from 'react'
import PropTypes from 'prop-types'

import LinearProgress from '@material-ui/core/LinearProgress'
import { connect } from 'react-redux'

export class LoadingBar extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.timer = null
    this.state = {
      completed: 0
    }
    this.progress = this.progress.bind(this)
  }

  componentDidMount () {
    this.timer = setInterval(this.progress, 500)
  }

  componentWillReceiveProps ({ loading }) {
    this.setState({ completed: loading.current })
  }

  componentWillUnmount () {
    clearInterval(this.timer)
  }

  progress () {
    const { completed } = this.state
    if (completed === 100) return

    const diff = Math.random() * 10
    this.setState({ completed: Math.min(completed + diff, 100) })
  }

  render () {
    return (
      <LinearProgress className='loading-bar' variant='determinate' value={this.state.completed} />
    )
  }
}

LoadingBar.propTypes = {
  value: PropTypes.number.isRequired,
  loading: PropTypes.object
}

const mapStateToProps = (state) => {
  return state
}

export default connect(mapStateToProps)(LoadingBar)
