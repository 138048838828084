import { map } from 'ramda'
const baseUrl = window._env_.REACT_APP_API_URL
const backslash = '/'
const concatUrl = url => baseUrl + url + backslash

const entities = {
  login: 'sign_in',
  lines: 'route',
  schedule: 'schedule',
  subscription: 'subscription',
  refreshToken: 'token/refresh',
  signout: 'sign_out',
  heartbeat: 'heartbeat',
  employees: 'user',
  trips: 'ride',
  bookings: 'booking',
  dailyStats: 'stats/daily',
  generalStats: 'stats/general'
}

export default map(concatUrl)(entities)
