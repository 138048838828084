export default [
  {
    name: 'subscriptions',
    title: 'Subscriptions',
    method: 'getSubscriptions',
    descriptionKey: 'total_subscriptions',
    hasAdd: true
  },
  {
    name: 'upcomingBookings',
    title: 'Upcoming Bookings',
    method: 'getUpcomingBookings',
    descriptionKey: 'total_upcoming_bookings',
    hasAdd: false
  },
  {
    name: 'pastBookings',
    title: 'Past Bookings',
    method: 'getPastBookings',
    descriptionKey: 'total_archived_bookings',
    hasAdd: false
  }
]
