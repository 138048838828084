import React from 'react'
import PropTypes from 'prop-types'
import DateNavigator from '../../date-navigator/date-navigator'
import AutocomleteInput from '../../autocomplete-input/autocomplete-input'

const ListTitle = ({ title, count, hasDateNavigator,
  onNavigateDate, hasAutocomplete, onAutoCompleteTyping,
  autocompletePlaceholder, autocompleteSuggestions, autocompleteSearchText,
  onAutocompleteClick, autocompleteDescriptionKey }) => {
  return (
    <div className='flex pa2 justify-between-l flex-column-m rtt overflow-container-ns list-title'>
      <div className='pa2 ma2 override-tachy-color br-pill white'>
        { count } {title}
      </div>

      <div className='pa2'>
        {
          hasDateNavigator &&
          <DateNavigator
            onChange={onNavigateDate}
          />
        }
        {
          hasAutocomplete &&
          <AutocomleteInput
            onSearch={onAutoCompleteTyping}
            placeholder={autocompletePlaceholder}
            suggestions={autocompleteSuggestions}
            searchText={autocompleteSearchText}
            onItemClick={onAutocompleteClick}
            descriptionKeys={autocompleteDescriptionKey}
          />
        }
      </div>
    </div>
  )
}

ListTitle.propTypes = {
  title: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  hasDateNavigator: PropTypes.bool,
  onNavigateDate: PropTypes.func,
  hasAutocomplete: PropTypes.bool,
  onAutoCompleteTyping: PropTypes.func,
  autocompletePlaceholder: PropTypes.string,
  autocompleteSearchText: PropTypes.string,
  autocompleteSuggestions: PropTypes.array,
  onAutocompleteClick: PropTypes.func,
  autocompleteDescriptionKey: PropTypes.array
}

ListTitle.defaultProps = {
  hasDateNavigator: false,
  hasAutocomplete: false
}

export default ListTitle
