import React from 'react'
import PropTypes from 'prop-types'
import Downshift from 'downshift'
import TextInput from '../text-input/text-input'
import AutocompleteSuggestions from './autocomplete-suggestions/autocomplete-suggestions'

const AutocompleteInput = ({
  onSearch, placeholder, suggestions, searchText, selectedItem,
  onItemClick, options, descriptionKeys }) => {
  const itemToString = i => i && i._id

  return (
    <Downshift
      selectedItem={selectedItem && selectedItem._id}
      onChange={onItemClick}
      itemToString={itemToString}
      inputValue={searchText}
      closeOnSelection
    >
      {
        ({ isOpen, getInputProps }) => {
          const inputProps = getInputProps()
          const inputClassName = 'autocomplete-input'
          const classNames = options.className ? (`${inputClassName} ` + options.className) : inputClassName
          const textOptions = Object.assign({}, inputProps, { className: classNames })
          const inputValue = (selectedItem && selectedItem._source && selectedItem._source.name) || searchText
          return (
            <div className='autocomplete'>
              <TextInput
                options={textOptions}
                placeholder={placeholder}
                onTyping={onSearch}
                value={inputValue}
              />
              <AutocompleteSuggestions
                suggestions={suggestions}
                descriptionKeys={descriptionKeys}
                isOpen={isOpen}
                onItemClick={onItemClick}
              />
            </div>
          )
        }
      }
    </Downshift>
  )
}

AutocompleteInput.propTypes = {
  searchText: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  suggestions: PropTypes.array.isRequired,
  onItemClick: PropTypes.func,
  options: PropTypes.object,
  descriptionKeys: PropTypes.array,
  selectedItem: PropTypes.object,
  classNames: PropTypes.string
}

AutocompleteInput.defaultProps = {
  options: {}
}

export default AutocompleteInput
