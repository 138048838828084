const mapie = (points) => {
  if (!points.length) return

  const getWaypoints = point => {
    return Object.assign({}, { location: `${point.loc.coordinates[1]}, ${point.loc.coordinates[0]}`, stopover: true })
  }
  const getMarkers = point => {
    const position = { lat: point.loc.coordinates[1], lng: point.loc.coordinates[0] }
    const title = point.name
    const description = point.address

    return { position, title, description }
  }

  const defaultCenter = getMarkers(points[0])

  const originObj = points[0].loc
  const origin = `${originObj.coordinates[1]}, ${originObj.coordinates[0]}`

  const destinationObj = points[points.length - 1].loc
  const destination = `${destinationObj.coordinates[1]}, ${destinationObj.coordinates[0]}`

  const waypointsObj = points.slice(1, points.length - 2)
  const waypoints = [...waypointsObj.map(getWaypoints)]

  const markers = [...points.map(getMarkers)]

  return { origin, destination, waypoints, markers, defaultCenter }
}

export default mapie
